interface StatusType {
  subtitle: string;
  url: string;
  title: string;
  description: string;
  city:
    | "osaka"
    | "sakai"
    | "hyogo_kobe"
    | "kyoto"
    | "shiga"
    | "wakayama"
    | "tokushima"
    | "tottori";
}

const STARTUP_STATUS_EN = {
  // label: "Hardware Acceleration" 0
  0: [
    {
      subtitle: "Osaka City\nOsaka Innovation Hub ",
      title: "OIH Startup Acceleration Program (OSAP)",
      city: "osaka",
      description:
        "Efforts to accelerate the business growth of startups companies through advice from experienced entrepreneurs or collaboration with large companies and venture capitals.\nThe program includes individual interviews by mentors, training and workshops. In addition, at the end of the program there will be a demo day where program participants explain business models and service details to venture capital firms and large companies to promote investment and business partnerships. The second term of 2022 will be counted as the 14th class. The amount of funding at the end of the 12th class  (as of March 2022) is 13.2 billion yen. ",
      url: "https://www.innovation-osaka.jp/acceleration/",
    },
    {
      subtitle: "Osaka Prefecture\nOsaka Innovation Hub",
      title: "Startup Initial program Osaka (SIO)",
      description:
        "This is a support program for specialized knowledge necessary to launch a business and is meant for individuals starting up a business or startup companies that are still new.\nIn addition to individual mentoring at least twice a month, there are pitch events and workshops that promote collaboration and cooperation with existing companies. Opportunities for professional consultation with banks, VCs, business companies, etc. are also available if necessary. Through approximately 6 months of support, participants are encouraged to grow and brush up their business plans. ",
      url: "https://sio.innovation-osaka.jp/",
      city: "osaka",
    },
    {
      subtitle: "Osaka Prefecture\nOsaka Innovation Hub",
      title: `Osaka Prefectural Startup Development Support Project "RISING!"`,
      description:
        "This is a program in which experienced entrepreneurs support entrepreneurs who are in the middle to late stages of their startup company. Through mentoring, theme-based study sessions, and exchange meetings with managers and executives of major companies, issues arising in the growth stages are supported . With the aim of discovering and supporting the growth of companies that will serve as role models from Osaka in the future, we aim to help half of the target companies achieve one of the following within three years after our support: quadruple the amount of sales, quadruple the number of clients, or double the number of employees.",
      url: "https://www.innovation-osaka.jp/rising/",
      city: "osaka",
    },
    {
      subtitle: "Osaka Metropolitan University",
      title: "Osaka Metropolitan University Health Tech Startups",
      description:
        "This is a business creation program for those who want to start a business in the healthcare field.  While utilizing the needs and seeds in the healthcare fields of Osaka Metropolitan University, students will learn the practical aspects of how to build a business, and aim to develop new products and services with the mentorship of various experts in the healthcare field. At the end of the program, a “demo day” will be held to present each business plan in order to find possible business partners and funds.",
      url: "https://www.chsi.osaka-cu.ac.jp/healthtech_startups/",
      city: "osaka",
    },
    {
      subtitle: "Osaka Business Development Agency",
      title: "Tech Business Accelerator SUITCH",
      description:
        "Using the keywords 「StartUp」×「Innovation」×「TeCH」, the Tech Business Accelerator SUITCH is a program for pushing the “switch” of startup companies aiming to use technology for innovation.\nThe program aims to develop new services for the WEB3 generation by utilizing block chain technology and metaverse. Business ideas based on technology seeds will be created and brushed up in the program. Under the guidance of mentors and experts, participants will work on business plans from the brainstorming to business model construction stages.",
      url: "https://teqs.jp/suitch2022",
      city: "osaka",
    },
    {
      subtitle:
        "Osaka Business Development Agency\nKansai Bureau of Economy, Trade and Industry",
      title: `Ladys' Entrepreneur Support Project\n"DISCOVER MYSELF"\n"Business Plan Presentation LED Kansai"`,
      description:
        "The Ladys' Entrepreneur Support Project is  a project that supports the growth of female entrepreneurs in various phases such as those who aim to start businesses in the 7 prefectures of the Kinki region, those who aim to expand their existing business, and LED Kansai finalists (ambassadors) who challenge themselves to create new value in collaboration with large companies. This program will help female entrepreneurs in Kansai level up to become big in Japan and in the world.",
      url: "https://discovermyself.jp/",
      city: "osaka",
    },
    {
      subtitle: "SAKAISHINJIGYO INC. (S-Cube)",
      title: `Acceleration Program`,
      description:
        "Since the coronavirus pandemic, lifestyles, business and other aspects of the public environment have changed dramatically. There is now a strong need for people to review the existing systems and values, and to take innovative approaches to change and development. This program provides the knowledge and network necessary for innovation through lectures, seminars, exchange meetings and mentoring. Let's commit ourselves to creating new value for the future, enhance our perspectives with our peers, and open up a new era together.",
      url: "https://www.s-cube.biz/acceleration",
      city: "sakai",
    },
    {
      subtitle: "Hyogo Prefecture\nKobe City",
      title: `SDGs CHALLENGE`,
      description:
        "In collaboration with UNOPS Innovation Centre Japan (Kobe), Hyogo Prefecture and Kobe City are implementing the SDGs CHALLENGE, a co-creation program aimed at solving global SDG issues. The program supports business development of SMEs working on seed stage startups and second startups.",
      url: "https://sdgs-challenge.jp/",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Hyogo Prefecture",
      title: `Post-Corona Re-challenge Entrepreneurship Development Program`,
      description:
        "In order to support the re-startup of businesses, the program gathers promising entrepreneurs from all over Japan who are looking for a second chance to start a business, and conducts an acceleration program to help them realize them. Seminars will also be held to share experiences of failure, issues/ experiences in re-challenging entrepreneurship, and other topics.\nThrough these activities, the program aims to create a community in Hyogo Prefecture that will support the participants and others in their efforts to re-challenge starting a business.",
      url: "",
      city: "hyogo_kobe",
    },
    {
      subtitle:
        "Kobe City\nFoundation for Biomedical Research and Innovation at Kobe\n(Co-sponsored by) Leave a Nest Co., Ltd.",
      title: `Med Tech Grand Prix KOBE`,
      description:
        "This acceleration program is designed to discover and nurture life science startups before and after their establishment.\nDemo days are held for the purpose of matching startups with private companies, etc., and afterwards the program accompanies and supports their growth.",
      url: "https://techplanter.com/medtech/2022/",
      city: "hyogo_kobe",
    },
    {
      subtitle:
        "Kobe City\nFoundation for Biomedical Research and Innovation at Kobe\n(Partnered firms and organizations)\nBayer Yakuhin, Ltd.\nSysmex Corporation\nAstraZeneca K.K.\nNippon Boehringer Ingelheim Co. Ltd.\nKyoto University Medical Science and Business Liaison Organization",
      title: `KANSAI Life Science Accelerator Program`,
      description:
        "This acceleration program supports the global expansion of life science startups. Winning companies at the pitch event will receive three months of mentoring by California Life Sciences (CLS), a support organization specializing in the life sciences field in California, USA, and networking opportunities with investors and operating companies.",
      url: "https://www.fbri-kobe.org/kbic/klsap/index.html",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Tottori Prefecture",
      title: `TORIGGER`,
      description: `TORIGGER is a new business creation program conducted in collaboration with Tottori Prefecture and the startup base Hayabusa Lab.\nIt aims to create a "startup ecosystem" in Tottori Prefecture by fostering an environment that supports the creation of new businesses in the prefecture through two programs: the "Entrepreneur (including second-stage entrepreneurs) Development Program" and the "Accelerator (startup supporter) Development Program". The program aims to create a startup ecosystem in Tottori Prefecture.`,
      url: "https://www.pref.tottori.lg.jp/297602.htm",
      city: "tottori",
    },
  ],
  // label: "Seminars / Workshops / Study Sessions" 1
  1: [
    {
      subtitle: "Japan Startup Support Association",
      title: "The JSSA ONLINE Meetup",
      description:
        "This is large-scale meetup program to gain an understanding of the latest situation on startups. The program will provide pitches and awards to startup companies in efforts to provide a space for learning, earning partnerships, and making connections in the coronavirus/post-coronavirus period.",
      url: "https://www.yumeplanning.jp/",
      city: "osaka",
    },
    {
      subtitle: "SAKAISHINJIGYO INC. (S-Cube)",
      title: "Entrepreneur Development Campus",
      description:
        "Regular IM interviews are conducted with the tenant companies. However, this project focuses on developing a business plan for those who are just starting out or in the idea stages of their business. For those in the idea stage, we will closely examine the sustainability and growth potential of the business being considered.\nBased on the completed business plan, the project will support the business until it is ready to be established (open for business) by helping to raise funds.",
      url: "https://www.s-cube.biz/",
      city: "sakai",
    },
    {
      subtitle: "SAKAISHINJIGYO INC. (S-Cube)",
      title: "Management Practice Study Session",
      description:
        "It is often difficult to concentrate when receiving general support. In order to deepen one's knowledge, we offer specialized support such as learning the basics from experts in the field, consulting on issues and helping to resolve them as soon as possible.",
      url: "https://www.s-cube.biz/",
      city: "sakai",
    },
    {
      subtitle: "SAKAISHINJIGYO INC. (S-Cube)",
      title: "U-30 Youth Startup Support Program",
      description: `This is a program to provide startup support to students and young individuals up to 30 years old. "Ideathon" and other events are offered to support the creation of business ideas.`,
      url: "https://www.s-cube.biz/",
      city: "sakai",
    },
    {
      subtitle:
        "Kobe City\nFoundation for Biomedical Research and Innovation at Kobe",
      title: "Life Science Startup Management Personnel Development Curriculum",
      description:
        "This curriculum aims to develop human resources for management of life science startups, which are in short supply nationwide. The program provides the knowledge necessary for startup managers, including simulated application for government grants using actual seeds, capital policy, and intellectual property strategy (a cooperative project of AMED, JST, and NEDO).",
      url:
        "https://www.fbri-kobe.org/kbic/service/field05/support.php#support-sec05",
      city: "hyogo_kobe",
    },
    {
      subtitle:
        "Kobe City\nFoundation for Biomedical Research and Innovation at Kobe\n(Co-sponsored by) Bayer Yakuhin, Ltd.",
      title: "Global Pharma Simulation Workshop",
      description:
        "This is a workshop for drug development start-up executives and researchers considering starting their own business to learn the process of therapeutic drug development in a global pharmaceutical company (co-hosted by Bayer Yakuhin Co., Ltd.). Participants can experience the development strategy and decision-making process at a global pharmaceutical company through a program originally developed by Bayer's headquarters.",
      url: "https://www.fbri-kobe.org/event/detail.php?event_id=512",
      city: "hyogo_kobe",
    },
    {
      subtitle:
        "Tokushima Prefecture\nTokushima Industrial Promotion Organization",
      title: "Heisei Chokyukan (entrepreneurship training courses, etc.)",
      description:
        "Entrepreneurship Training Course\nExperts in various fields, including businessmen, venture entrepreneurs, bankers, and SME diagnosticians, will explain the basic knowledge necessary for starting a business. In the practical training for creating a business plan, a SME diagnostician will give a lecture on actual methods of creating a business plan.\nThis course is provided with the collaboration of Tokushima Prefecture, Tokushima Industrial Promotion Organization, and the University of Tokushima.",
      url: "https://www.our-think.or.jp/317758/",
      city: "tokushima",
    },
  ],
  // label: "Consultation / Expert Dispatch / Mentoring" 2,
  2: [
    {
      subtitle: "KANSAI Association of Corporate Executives ",
      title: "The Declaration of Startup Friendly Kansai ",
      city: "osaka",
      description:
        "Under the Kansai Startup Backing Initiative, we aim to make the Kansai region a highly innovative area full of creativity and fully open to the world. To this end, now that a startup ecosystem is emerging in the Kansai region, we hereby announce that all supporting companies are quite positive in: inheriting a dynamic corporate culture typified by a unique way of thinking as well as a “Just try it” culture, both of which we have long fostered; and staying close beside next-generation entrepreneurs striving hard to launch new businesses, thereby providing them with necessary support.",
      url:
        "https://www.kansaidoyukai.or.jp/wp-content/uploads/2021/07/37885e8b590a9f5369d07df13fd3e911.pdf",
    },
    {
      subtitle:
        "Tokushima Prefecture\nTokushima Industrial Promotion Organization",
      title:
        "Tokushima Industrial Promotion Organization's program to dispatch specialists and rapid management assistance",
      description: `In addition to our regular consultation services, we offer the "Rapid Management Assistance Program," in which we dispatch experts in various fields to provide diagnosis and advice. Experts can be dispatched to help SMEs and founders aiming for management innovation and improvement with various issues (general management, marketing, finance, legal affairs, personnel and labor issues, tax issues, business start-up, IT, patents, sales channel development, ISO certification acquisition, etc.).`,
      url: "https://www.our-think.or.jp/other/dispatch/",
      city: "tokushima",
    },
  ],
  // label: "Awards" 3,
  3: [
    {
      subtitle: "METI Kansai",
      title: "J-Startup KANSAI",
      city: "osaka",
      description:
        'The Ministry of Economy, Trade and Industry (METI) launched the J-Startup program in 2018, and is providing intensive support to approximately 140 startup companies nationwide. \nJ-Startup KANSAI will be implemented as Kansai’s version of METI\'s J-Startup program. \nJ-Startup KANSAI selects promising startups from the Kansai region seeking nationwide and worldwide expansion as "J-Startup KANSAI Companies," and provides intensive support through private-public partnerships to support the dramatic growth of selected companies. \nBy identifying promising startup companies that will serve as a role model from Kansai, and by establishing mechanisms to support and support them in the region, we aim to further strengthen the startup ecosystem in Kansai.',
      url: "https://next-innovation.go.jp/j-startup-kansai/english/",
    },
    {
      subtitle: "Sakai City",
      title: "Venture Procurement Certification System",
      description:
        "This is a certification system in which the mayor certifies companies that seek to develop new business areas by producing new products or services. The city supports the development of sales channels by creating the initial demand and improving the reliability of the certified companies, thereby stimulating the economy.",
      url:
        "https://www.city.sakai.lg.jp/sangyo/shienyuushi/chusho/busexp/tyoutatsu.html",
      city: "sakai",
    },
    {
      subtitle:
        "Advanced Science, Technology & Management Research Institute of KYOTO (ASTEM)",
      title: "Oscar Certification System",
      description: `By awarding "Oscars "to SMEs that are actively engaged in management innovation through excellent business plans (power-up plans) and providing ongoing support to help them realize them, the program nurtures SMEs that will play a central role in the Kyoto economy.`,
      url: "https://www.astem.or.jp/smes/oscar",
      city: "kyoto",
    },
    {
      subtitle:
        "Advanced Science, Technology & Management Research Institute of KYOTO (ASTEM)",
      title: "Kyoto City Venture Business Connoisseur Committee",
      description: `In order to discover and nurture venture businesses that will lead the Kyoto economy in the next generation, the "Kyoto City Venture Business Mekiki Committee" evaluates business potential, technology, ideas, etc. business plans. By gathering excellent business plans from all over the country and encouraging them to start their own businesses, we hope to be able to revitalize Kyoto's economy.`,
      url: "https://www.venture-mekiki.jp/",
      city: "kyoto",
    },
  ],
  // label: "Pitch Events" 4,
  4: [
    {
      subtitle: "Osaka Innovation Hub",
      title: "Umekita Pitch",
      description: `This is a pitch event that connects startups to large companies/VCs.\nThe Osaka Innovation Hub (OIH)'s regular pitch event "Umekita Pitch" is an event that sets a theme every month so that startups, large and medium-sized companies, and venture capital businesses can make strong connections that lead to collaboration.\nOIH's members (startup companies), regardless of whether or not they are located in Osaka, have introduced products and services that make full use of the latest technology, which has led to business alliances and successful fundraising.`,
      url: "https://www.innovation-osaka.jp/ja/service/pitch/umekita/",
      city: "osaka",
    },
    {
      subtitle: "Osaka Innovation Hub",
      title: "OIH Starters Pitch",
      description: `"Pitches are hard to avoid when aspiring to start a business. This is a program to help entrepreneurs overcome these obstacles.\nIn order to raise funds or form business alliances with other companies through a pitch, it is important to have a solid business plan and to explain your business in a concise and easy-to-understand manner.\nThis program will help participants brush up their skills and business plans with people who share the same aspiration of starting a business.  During each session, experienced entrepreneurs and venture capitalists with expertise in their fields will mentor and give advice on business plans."`,
      url: "https://www.innovation-osaka.jp/ja/service/pitch/starters/",
      city: "osaka",
    },
    {
      subtitle: "Osaka Innovation Hub",
      title: "Mirainno Pitch",
      description: `This is a pitch event focused on information communication fields such as IoT, robots, and various applications. Mirainno Pitch is a pitch event aimed at supporting the commercialization of products, applications, and services based on the ideas of those who aim to start a business in the information and communication field.  In this event, selected student and general category participants will take part in a 7-minute pitch contest.  This event also serves as the Kinki region preliminary of the "Entrepreneur Koshien/Entrepreneur Expo," which is co-sponsored by the Ministry of Internal Affairs and Communications, and National Institute of Information and Communications Technology. Winning regional participants will continue on to the nationwide competition. `,
      url: "https://www.innovation-osaka.jp/ja/service/pitch/mirainno/",
      city: "osaka",
    },
    {
      subtitle: "Deloitte Tohmatsu Venture Support Co., Ltd.",
      title: "Morning Meetup",
      description: `This is a program in which venture companies pitch to large companies, VCs, media, etc. The program promotes the growth of ventures aiming for an IPO (stock listing), creating business alliances with large companies, and creating open innovation for large companies.\nThe program is hosted by Deloitte Tohmatsu Venture Support Co., Ltd.`,
      url: "https://www.innovation-osaka.jp/ja/service/pitch/morning-meet-up/",
      city: "osaka",
    },
    {
      subtitle: "Wakayama Prefecture",
      title: "Matching event with startup creation support team",
      description: `Pitch events will be held for those who are seeking to start a business in Wakayama Prefecture or to start a new business utilizing Wakayama Prefecture's local resources. For those who participate in the pitch event, a "Startup Creation Support Team" consisting of investment companies, large corporations, financial institutions, etc. will support the development of their businesses.`,
      url: "https://www.pref.wakayama.lg.jp/prefg/063100/sougyo.html",
      city: "wakayama",
    },
  ],
  // label: "Business Incubation" 5,
  5: [
    {
      subtitle: "Osaka Business Development Agency",
      title: "Operation of TEQS Software Industry Plaza",
      description: `This is a support base for people who solve problems using technology in the technology business sector.  At the 5G X LAB OSAKA in the facility, it is possible to develop and verify products and services in an dedicated environment using 5G communication.\nWe provide comprehensive support from business development to training and demonstration, such as support for the “creation” of businesses through acceleration programs, “cultivation” through incubation facilities, and experimentation support through “testing.” Equipment such as a 3D printer and laser cutter are available for use.`,
      url: "https://teqs.jp/",
      city: "osaka",
    },
    {
      subtitle: "Business Innovation Center Osaka",
      title: "Risshian",
      description: `We provide total support from business plan creation to launching a business. Support for tacking the problems of starting is business is given through workshops, interviews and study sessions.  This is a program where participants come up with and brush up a business plan to start a business in 6 months.`,
      url: "https://www.sansokan.jp/akinai/risshian/",
      city: "osaka",
    },
    {
      subtitle: "SAKAISHINJIGYO INC. (S-Cube)",
      title: "SAKAISHINJIGYO INC. (S-Cube)",
      description: `S-Cube is located about a 4-minute walk from Nakamozu Station on the Midosuji Subway Line. This is an incubation facility that houses about 50 companies, including a startup preparation desk.\nS-Cube offers a full range of support programs for startups and venture companies, as well as open space for meetings. The environment is one where people from various industries, backgrounds, aspirations and destinations can work together in a friendly environment.`,
      url: "https://www.s-cube.biz/",
      city: "sakai",
    },
    {
      subtitle:
        "Organization for Small & Medium Enterprises and Regional Innovation",
      title: "Kyoto University Katsura Venture Plaza (North Wing/South Wing)",
      description: `The Kyoto University Katsura Venture Plaza supports the growth of those who aim to industrialize "university-originated technology seeds" or those interested in creating new businesses.`,
      url: "https://www.smrj.go.jp/incubation/kkvp/index.html",
      city: "kyoto",
    },
    {
      subtitle:
        "Organization for Small & Medium Enterprises and Regional Innovation",
      title: "Creation Core Kyoto Mikuruma",
      description: `Creation Core Kyoto Mikuruma is an incubation facility that supports ventures and SMEs seeking to develop new businesses in the wellness field by utilizing a network of industry, academia, and government.`,
      url: "https://www.smrj.go.jp/incubation/cckm/index.html",
      city: "kyoto",
    },
    {
      subtitle:
        "Organization for Small & Medium Enterprises and Regional Innovation",
      title:
        "D-egg (Doshisha University Collaborative Entrepreneur Development Facility) ",
      description: `Creating new industries of the future. D-egg is an incubation facility established by Doshisha university. The facility will work towards contributing to the local economy and society by promoting industry-academia-government collaboration and new industries through activities centered in the local community.`,
      url: "https://www.smrj.go.jp/incubation/d-egg/index.html",
      city: "kyoto",
    },
    {
      subtitle: "Keihanna Interaction Plaza Incorporated",
      title: "Kyoto Prefecture Keihanna Venture Center",
      description: `In June 2000, Kyoto Prefecture opened the "Incubate Room," a base for creating new industries and fostering venture businesses, at Keihanna Plaza in Kansai Science City, which aims to become a "City of New Industry Creation.`,
      url: "https://www.keihanna-plaza.co.jp/office-labo/incubate-room/",
      city: "kyoto",
    },
    {
      subtitle: "Uji City",
      title: "Uji Venture Company Incubation Factory",
      description: `We support the future of manufacturing venture companies from Kyoto.`,
      url: "https://www.city.uji.kyoto.jp/site/vif/",
      city: "kyoto",
    },
    {
      subtitle:
        "Kyoto Nishijin Machiya Studio (Specified Non-profit Corporation) ",
      title: "Nishijin Business Incubation Center ",
      description: `This is a business incubation center operated by the NPO Kyoto Nishijin Machiya Studio in cooperation with Kyoto Prefecture and NTT West to support those who want to start a business, recently started venture businesses, and small business owners working on a freelance basis.`,
      url: "https://www.nishijin-bic.kyoto/",
      city: "kyoto",
    },
    {
      subtitle: "Kobe City\nKobe Urban Promotion Service Co., Ltd.",
      title: "Startup Creative Lab (SCL)",
      description: `This lab is a base for startups before and after their establishment to pursue research and development. It is equipped with research equipment necessary for research and development, a shared wet lab that can be used with a low initial investment, as well as shared offices that can be registered. The startups who move in can take advantage of the human network of the Kobe Biomedical Innovation Cluster and support measures that contribute to commercialization. Subsidies for usage fees are available from Kobe City.`,
      url: "https://kobe-scl.com/",
      city: "hyogo_kobe",
    },
    {
      subtitle:
        "Tokushima Prefecture\nTokushima Industrial Promotion Organization",
      title: "Tokushima SOHO Place",
      description: `In order to promote job creation and the creation of new industries, we provide small-scale offices for IT-based SOHO businesses, etc., and provide comprehensive support for challengers in the startup phase.`,
      url: "https://www.our-think.or.jp/?p=2246",
      city: "tokushima",
    },
    {
      subtitle:
        "Tokushima Prefecture\nTokushima Prefectural Industrial Technology Center",
      title:
        "Rental Factory: Entrepreneurship Support Facility (Ashitaba Plant)",
      description: `A room with factory specifications (Entrepreneur Support Facility "Ashitaba Plant") is available for those who have an idea or technology and are planning to start a manufacturing business or have been in the manufacturing business for less than five years. Since this facility is attached to the Industrial Technology Center, it is possible to receive technical support from the Center as needed.`,
      url: "https://www.itc.pref.tokushima.jp/01_service/06facility_03.shtm",
      city: "tokushima",
    },
  ],
  // label: "Subsidies / Grants / Incentives" 6,
  6: [
    {
      subtitle: "Osaka Prefecture\nBusiness Innovation Center Osaka",
      title: "Osaka Entrepreneur Growing Up ",
      description: `This is a program by Osaka Prefecture meant to discover and nurture promising entrepreneurs who will lead the Osaka economy in the future. By doing so, the program aims to promote economic and social revitalization and achieve sustainable growth.  This program will be centered on implementing business plan contests.`,
      url: "https://www.obda.or.jp/jigyo/growingup.html",
      city: "osaka",
    },
    {
      subtitle: "Sakai City",
      title: "Incubation Facility Resident Support Assistance",
      description: `The purpose of this program is to promote entrepreneurship and new business development among the tenants of the incubation facility (S-Cube) and to revitalize the local economy. The program subsidizes a portion of the rent for individuals or corporations with a detailed business plan.`,
      url:
        "http://www.city.sakai.lg.jp/sangyo/shienyuushi/sogyo/sozocenter.html",
      city: "sakai",
    },
    {
      subtitle: "Hyogo Prefecture",
      title:
        "R&D Support Program for Fostering Growth Industries (former COE Program)",
      description: `In order to foster growth industry sectors in mainly next-generation industries, Hyogo Prefecture has established the "R&D Support Program for Fostering Growth Industries (former COE Program)," which openly calls for proposals of joint research projects in their early stages conducted in collaboration of the industry-academia-government sectors.`,
      url: "https://web.pref.hyogo.lg.jp/sr09/ie03_000000002.html",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Hyogo Prefecture",
      title: "IT Strategy Promotion Program",
      description: `In order to promote the information and telecommunications industry in the prefecture and to revitalize the region, a portion of the expenses for IT-related businesses that establish IT-related offices are subsidized.`,
      url: "https://web.pref.hyogo.lg.jp/sr10/tashizen/index.html",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Hyogo Prefecture",
      title: "Co-working Space Establishment Support Program",
      description: `In order to create a base for entrepreneurship in the region and to accommodate diverse work styles such as teleworking and sideline jobs, the program subsidizes a portion of the expenses for businesses that open new co-working spaces for entrepreneurs, etc.`,
      url: "https://web.pref.hyogo.lg.jp/sr10/koudoit.html",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Hyogo Prefecture",
      title: "Entrepreneurship Support Program",
      description: `In order to promote entrepreneurship, the program subsidizes a portion of the costs for entrepreneurs who aim to start a business or establish a second business in the prefecture.`,
      url: "https://web.hyogo-iic.ne.jp/kigyo",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Hyogo Prefecture",
      title:
        "Hyogo Agriculture, Commerce and Industry Cooperation Fund Subsidy Program",
      description: `The program subsidizes a portion of the project costs for projects undertaken by partnerships between SMEs and agriculture, forestry, and fishery companies in order to realize attractive agricultural businesses and develop new products and services that meet market needs by utilizing regional resources from Hyogo prefecture, such as agricultural, forestry, and marine products.`,
      url: "https://web.hyogo-iic.ne.jp/kigyo/renkeifund",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Hyogo Prefecture",
      title: "Post-Corona Challenge Support Program",
      description: `This program supports startups and other SMEs with innovative ideas and technologies, in their efforts to solve new social issues such as digitalization and non-contact, that have emerged as a result of the coronavirus pandemic.`,
      url: "https://web.hyogo-iic.ne.jp/kigyo/postcorona",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Kobe City",
      title: "Subsidies to promote the location of innovation centers",
      description: `To promote the establishment of co-working spaces with incubation functions, up to 6.5 million yen will be subsidized for building renovation and acquisition of office equipment.`,
      url: "https://kobe-investment.jp/system/startup/",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Kobe City",
      title: "Startup Subsidies",
      description: `Subsidies of up to 13 million yen over a period of up to three years are available for startups opening new business offices in Kobe City. The subsidy covers building renovation costs, office equipment acquisition costs, office rent, telecommunication line usage fees, and personnel expenses.`,
      url: "https://kobe-investment.jp/system/startup/",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Kobe City",
      title: "Office Rent Subsidies",
      description: `For companies from outside of Kobe City, 1/4 of the office rent (1/2 for IT-related companies) will be subsidized for 3 years (5 years for companies with a leased area of 1,500 m2 or more). There is an additional subsidy of up to 1.2 million yen per person for employment.`,
      url: "https://kobe-investment.jp/system/office/",
      city: "hyogo_kobe",
    },
    {
      subtitle:
        "Kobe City\nFoundation for Biomedical Research and Innovation at Kobe",
      title: "Kobe Life Science Gap Fund",
      description: `This is a subsidy program (maximum amount: 5 million yen, subsidy rate: 10/10) for startups to verify the potential for social implementation of their technologies before commercialization. The subsidy covers the cost of research, prototyping, and intellectual property related expenses that will contribute to commercialization.`,
      url: "https://www.fbri-kobe.org/cluster/support/jyosei1.php",
      city: "hyogo_kobe",
    },
    {
      subtitle:
        "Tokushima Prefecture\nTokushima Industrial Promotion Organization",
      title: "Wakuwaku Business Startup Support Subsidy",
      description: `After open recruitment and a screening process, the program will subsidize a part of the necessary expenses for those who start a new business to solve social issues in the prefecture.\n（The maximum subsidy amount is 2,000,000 yen, and the subsidy rate is 1/2.)`,
      url: "https://www.our-think.or.jp/316816/9",
      city: "tokushima",
    },
    {
      subtitle: "Tokushima Prefecture",
      title: "Hometown Entrepreneur Support Project",
      description: `Crowdfunding contributions will be solicited for projects approved by the governor, and the prefecture will provide an additional subsidy to the funds raised.`,
      url: "https://www.pref.tokushima.lg.jp/furusato-ouen/",
      city: "tokushima",
    },
    {
      subtitle: "Wakayama Prefecture",
      title: "Wakayama Venture Business Attraction Program",
      description: `Incentives and subsidies are offered to venture companies that rent or build offices or factories in Wakayama Prefecture.`,
      url: "https://www.pref.wakayama.lg.jp/prefg/063100/d00204783.html",
      city: "wakayama",
    },
    {
      subtitle: "Tottori Prefecture",
      title: "Tottori Entrepreneurial Startup Try Subsidy",
      description: `This subsidy is provided to entrepreneurs who intend to start an innovative business in Tottori Prefecture by utilizing new business ideas, technologies, or know-how, etc. The subsidy covers a part of the expenses required to implement the business before or after the start-up.`,
      url: "https://www.pref.tottori.lg.jp/277668.htm",
      city: "tottori",
    },
    {
      subtitle: "Tottori Prefecture",
      title:
        "Subsidy for attracting and nurturing pioneering laboratories in Tottori",
      description: `This subsidy program provides a wide range of support for up to four years, from preliminary site surveys to the establishment of satellite offices to the establishment of permanent R&D facilities, for companies that are developing new and innovative products and services or relocating part of their functions and operations from metropolitan areas to Tottori Prefecture.`,
      url: "https://www.pref.tottori.lg.jp/294244.htm",
      city: "tottori",
    },
    {
      subtitle: "Tottori Prefecture",
      title:
        "Tottori Business Succession Support Subsidy (support type of relocating residents and business founders)",
      description: `When a person relocating to Tottori Prefecture or a founder of a business takes over a business from a SME in Tottori Prefecture, this program will support the initial investment such as the introduction of necessary equipment, etc. for the start of the new business.`,
      url: "https://www.pref.tottori.lg.jp/273822.htm",
      city: "tottori",
    },
  ],
  // label: "Comprehensive Support / Hands-on Support" 7,
  7: [
    {
      subtitle:
        "Tokushima Prefecture\nTokushima Industrial Promotion Organization",
      title: "Tokushima Startups Platform",
      description: `This is a platform where those who aim to start a business and company representatives/university researchers who aim to launch a new business gather in Tokushima Prefecture, and supporters provide collaborative support.`,
      url: "https://www.our-think.or.jp/?mode=detail&p=307755",
      city: "tokushima",
    },
    {
      subtitle:
        "Tokushima Prefecture\nTokushima Industrial Promotion Organization",
      title: "Attaka Business Startup and Support Program",
      description: `The "Attaka Business Startup and Support Program" is a program to support community-based startups by certifying outstanding startup plans that utilize Tokushima Prefecture's unique resources such as local products and culture, as well as the unique abilities and experience of individuals regardless of size or type of business.\nPlease consider applying for this program if you are interested in starting a business.\nApplications are accepted at any time.`,
      url: "https://www.our-think.or.jp/?mode=detail&p=2229",
      city: "tokushima",
    },
  ],
  // label: "Portal Site / Information Dissemination" 8,
  8: [
    {
      subtitle: "Kyoto Chamber of Commerce and Industries",
      title: "Stasapo Kyoto ",
      description: `This is an information site operated by the Kyoto Chamber of Commerce and Industry to support those interested in starting a business in Kyoto City. The site provides basic knowledge and useful seminar information for those who are interested in starting a business and those who are preparing to start a business in the near future.`,
      url: "http://www.stasapo.kyoto/",
      city: "kyoto",
    },
    {
      subtitle: "Kyoto City",
      title: "Kyoto City Website for Attracting Businesses (Kyo-working)",
      description: `The site provides basic information on attracting companies to Kyoto City (location assistance/information, etc.), reports on talk events featuring experts, interviews with Kyoto workers who practice "Kyo-working," and various other content that promotes the attractiveness of Kyoto.`,
      url: "https://kyo-working.city.kyoto.lg.jp/",
      city: "kyoto",
    },
  ],
  // label: "Equipment" 9,
  // 9: [],
  // label: "Funds" 10,
  9: [
    {
      subtitle: "Nippon Venture Capital Co., Ltd.",
      title:
        "Kyoto University Venture NVCC2 Investment Business Limited Liability Partnership",
      description: `This Fund aims to contribute to the revitalization of the Japanese economy by promoting the entrepreneurship of research results at Kyoto University, providing multifaceted support for venture companies and other agencies associated with Kyoto University, promoting corporate growth, creating and fostering university-launched venture companies, and promoting industry-academia collaboration.`,
      url: "https://www.nvcc.co.jp/",
      city: "kyoto",
    },
    {
      subtitle: "Kyoto University Innovation Capital Co., Ltd.",
      title:
        "Innovation Kyoto 2021 Investment Limited Partnership KYOTO-iCAP2 Fund",
      description: `The amount raised by the KYOTO-iCAP No. 2 Fund is 18.14 billion yen. The fund will mainly invest in venture companies that utilize Kyoto University's research results and support their growth, but a portion of the fund will also be used to support ventures originating from national universities other than Kyoto University.`,
      url:
        "https://www.kyoto-unicap.co.jp/icap/wp-content/uploads/Second-Fund_PressRelease_20210112.pdf",
      city: "kyoto",
    },
    {
      subtitle:
        "Kyoto City\nFuture Venture Capital Co.,Ltd.\nThe Kyoto Shinkin Bank\nKyoto Chuo Shinkin Bank\nKyoto Research Park\nJapan Finance Corporation Kyoto Branch",
      title:
        "Kyoto City Startup Support Fund No.1\nKyoto City Startup Support Fund No. 2",
      description: `In addition to fund investors, these funds work with the City of Kyoto and Japan Finance Corporation to support young entrepreneurs, startup venture companies, and second-stage companies based in Kyoto City to foster local industry through investment and post-investment support.`,
      url: "https://www.city.kyoto.lg.jp/sankan/page/0000275652.html",
      city: "kyoto",
    },
    {
      subtitle: "The Kyoto Shinkin Bank\nFuture Venture Capital Co., Ltd",
      title: "Innovation C Fund",
      description: `This fund aims to contribute to the growth of unlisted companies in Kyoto and other Kyoto Shinkin Bank business areas that contribute to the revitalization of the local economy through creative technologies, services and business models, thereby contributing to the realization of solutions to social issues and the growth of such companies.`,
      url: "https://www.kyoto-shinkin.co.jp/whatsnew/pdf2018/n18-0110.pdf",
      city: "kyoto",
    },
    {
      subtitle: "The Bank of Kyoto, Ltd.",
      title: "Bank of Kyoto Kagayaku Mirai Support Fund No.2",
      description: `The Bank of Kyoto has established the "Bank of Kyoto Kagayaku Mirai Support Fund No. 2 Investment Limited Liability Partnership" to support promising startup companies, venture companies and SMEs in order to contribute to the revitalization of the local economy and regional development. The purpose of this fund is to provide various types of management support to companies with growth potential, not only by investing the necessary funds to help them grow into core companies in the region, but also from a non-financial standpoint.`,
      url: "https://www.kyotobank.co.jp/houjin/support/grow.html",
      city: "kyoto",
    },
    {
      subtitle: "Kyoto Chuo Shinkin Bank",
      title: "Kyoto Chuo Shinkin Bank Venture and Investment Fund No.5",
      description: `A new investment fund was established to continue discovering and fostering promising local venture companies. In close collaboration with the Regional Development Department, which was newly established in November 2017 as part of the reorganization of the Bank's headquarters, the fund will actively provide support mainly to companies whose life stage is from the startup to growth phases.`,
      url: "https://www.chushin.co.jp/cms_source/files/180509_news.pdf",
      city: "kyoto",
    },
    {
      subtitle: "Hyogo Prefecture\nKobe City",
      title: "Hyogo Kobe Startup Fund",
      description: `In order to achieve sustainable economic growth, Hyogo Prefecture and Kobe City have been promoting efforts to support the accumulation and nurturing of startups that can bring innovation to society. To further revitalize the local economy and improve the competitiveness of local industries, the "Hyogo Kobe Startup Fund "was created in cooperation with private companies and other organizations in order to invest in startups with potential for rapid growth.`,
      url: "https://web.hyogo-iic.ne.jp/kigyo/startup",
      city: "hyogo_kobe",
    },
  ],
  // label: "Loans / Equity Loans" 11,
  10: [
    {
      subtitle: "Tokushima Prefecture",
      title:
        "Unsecured Funds for Founders\n【for Attaka businesses】\n （for Attaka businesses: female founders）",
      description: `Loans of up to 3.5 million yen are available for operating and equipment funds.\n*Businesses certified under the Attaka Business Plan are eligible to borrow at more favorable loan and guarantee rates.`,
      url:
        "https://www.pref.tokushima.lg.jp/jigyoshanokata/sangyo/shokogyo/5015570/",
      city: "tokushima",
    },
    {
      subtitle: "Tottori Prefecture",
      title: "Funds to support business startups",
      description: `Financial institutions in Tottori Prefecture will provide long-term, low-interest loans (startup support funds) for working capital and equipment necessary for the implementation of businesses related to startups.`,
      url: "https://www.pref.tottori.lg.jp/244761.htm",
      city: "tottori",
    },
  ],
  // label: "Matching" 12,
  11: [
    {
      subtitle: "Tokushima Prefecture",
      title: "Tokushima Trial Order Program",
      description: `Tokushima Prefecture will take the initiative in purchasing new products and services developed by companies in Tokushima, and will support the development of sales channels by establishing a track record of receiving orders from government agencies, conducting monitoring surveys for a certain period of time, and returning the results to the companies to help them improve their products and other services.`,
      url:
        "https://www.pref.tokushima.lg.jp/jigyoshanokata/sangyo/shokogyo/5037416",
      city: "tokushima",
    },
    {
      subtitle:
        "Tottori Prefecture (Administration: Tottori Industrial Promotion Organization)",
      title: "Tottori Collaborative Value Open Innovation Platform",
      description: `Tottori Industrial Promotion Organization (TIPO) has launched the "Tottori Collaborative Value Open Innovation Platform" to provide support from project formation to new business development by promoting matching and collaboration between companies in Tottori Prefecture and startups nationwide that are aiming to enter new fields or restructure businesses with an eye toward the next era.`,
      url: "https://www.pref.tottori.lg.jp/298050.htm",
      city: "tottori",
    },
  ],

  //label: "Others" 13
  12: [
    {
      subtitle:
        "Global Innovation Conference Hack Osaka Executive Committee（Osaka City, Urban Innovation Institute, JETRO, Osaka,Osaka Business Development Agency）",
      title: "Hack Osaka - International Conference on Innovation",
      city: "osaka",
      description:
        "Hack Osaka is the place to be for all entrepreneurs, investors, corporations and students who believe in the value of brining diverse people together to drive a chain of innovations. It is an annual international conference hosted by Osaka City. Startups from all around Asia and the world take on the stage to pitch, get a booth for exposure and meet with VCs on site. Hack Osaka also attracts prominent speakers and panelists, whose stories will take your entrepreneurial mindset to the next level.",
      url: "https://www.innovation-osaka.jp/hackosaka/en/index.php",
    },
    {
      subtitle: "Osaka Innovation HUB（OIH）",
      title: "GET IN THE RING - Global Pitch Event",
      city: "osaka",
      description:
        "GET IN THE RING is the global startup pitch event hailing from the Netherlands. Since its launch in 2012, GITR’s fun and exciting format, where two startups step onto the real boxing ring and pitch like battle with each other, has been increasingly popular world-wide. The winners from over 150 locations and 100 countries every year get a ticket to the Global Meetup. OIH has been the local host of GITR in Japan for  more than 6  years.",
      url: "https://www.innovation-osaka.jp/gitr/en/",
    },
    {
      subtitle: "Osaka City\nBusiness Innovation Center Osaka ",
      title: "Osaka Startup Visa　Support",
      city: "osaka",
      description:
        "Startup Visas are available in Osaka City for international startup founders for up to one year.Application Support Desk for International Founders helps entrepreneurs from outside Japan apply for the Startup Visa and set up their business in Osaka.\n" +
        " – Receipt of the Plan of Business Startup Activities\n" +
        " – Assistance in preparing the Plan of Business Startup Activities\n" +
        "– Assistance in setting up a business in Osaka once the “Startup Visa” is granted",
      url: "https://www.sansokan.jp/startupvisa/en/",
    },
    {
      subtitle: "Osaka City\nOsaka Business Development Agency",
      title: "Osaka Innovation Hub",
      city: "osaka",
      description:
        'The Osaka Innovation Hub, established by the City of Osaka in 2013, is an innovation creation center where entrepreneurs and technologists who are challenging the world gather. Under the theme of "From Osaka to the World," the hub holds approximately 250 events and programs a year that lead to the creation and scaling up of new businesses. It also supports the formation of communities and the commercialization of business plans through the exchange of diverse people, companies and ideas. We are promoting the establishment of an "innovation ecosystem" in the Kansai region, where entrepreneurs are created, nurtured, and made successful, and where successful people give birth to the next successful people.',
      url: "https://www.innovation-osaka.jp/",
    },
    {
      subtitle:
        "Global Innovation Conference Hack Osaka Executive Committee　（Osaka City, Urban Innovation Institute, JETRO, Osaka,Osaka Business Development Agency）",
      title: "Hack Award",
      city: "osaka",
      description:
        'The “Hack Award” is a global business plan contest where entrepreneurs will give a talk and showcase their ideas and business models. In this coming year, we will be focusing mainly on "next-generation mobility" and "infrastructure (maximizing efficiency of existing infrastructure as well as next-generation infrastructure including telecommunications, etc.)" with the theme of “Future Society (abundant, secure and sustainable society).” Presenting startups will be from both domestic and overseas.',
      url: "https://www.innovation-osaka.jp/hackosaka/en/hack-award.php",
    },
    {
      subtitle: "Secretariat: Osaka Business Development Agency, ",
      title: "Osaka Startup Ecosystem Consortium",
      city: "osaka",
      description:
        "The Osaka Startup Ecosystem Consortium is pushing initiatives that produce startups that can play an active global role in partnership with businesses, universities, government, cooperative organizations, and more. This consortium, in collaboration with the consortiums of Kyoto and the Hyogo-Kobe region, was selected as a Global Startup City under the Cabinet Office strategy of “Beyond Limits. Unlock Our Potential” on July 14,2020.",
      url: "https://www.innovation-osaka.jp/startup-ecosystem/",
    },
    {
      subtitle: "Kansai Future Summit 2021 Management office",
      title: "Kansai Future Summit ",
      city: "osaka",
      description:
        "In order for Japan to grow economically further and become a true leader in globalization, it will be important to grow beyond Tokyo-centrism and for regional companies in Japan to exert worldwide impact. To showcase the Kansai region as an innovation center that produces such companies, we hold the Kansai Future Summit. This event will foster regional development by bringing together influential and interesting people from industry, government and academia. Under our theme of “Fun people gather to create fun connections”, we will build a “network of interesting and fun people such as industry pioneers and industry leaders.”",
      url: "https://kansai-future.com/en/",
    },
    {
      subtitle:
        "Osaka Prefecture \nOsaka City \nOsaka Chamber of Commerce and Industry",
      title: "Osaka Business and Investment Center (O-BIC) ",
      city: "osaka",
      description:
        "Osaka Business and Investment Center (O-BIC) was established in April 2001 by the Osaka Prefectural Government, the Osaka City Government, and the Osaka Chamber of Commerce and Industry in a joint initiative. As your one-stop service center for business investing, O-BIC provides prospective foreign investors with a comprehensive yet detailed support system. International companies, foreign government offices and economic organizations as well as foreign-capitalized companies located in Japan are invited to use the center to obtain accurate information and essential advice for entering the Osaka market.",
      url: "https://o-bic.net/",
    },
    {
      subtitle:
        "Osaka Chamber of Commerce and Industry \nOsaka Business and Investment Center (O-BIC) ",
      title: "GLOBAL INNOVATION FORUM OSAKA　2021",
      city: "osaka",
      description:
        'Organized by the Osaka Chamber of Commerce and Industry (OCCI) and the Osaka Business and Investment Center (O-BIC), this is an online exhibition and business meeting for domestic and foreign start-ups and companies promoting cutting-edge technologies and innovative initiatives. \nThe program also includes live pitches and an online symposium. \nThe GIF will be a place to meet with advanced startups and partners from around the world and create new business opportunities "from Osaka.',
      url: "https://gif.osaka.cci.or.jp/en/",
    },
    {
      subtitle: "Osaka City",
      title: "International Business Promotion Center (IBPC) Osaka",
      city: "osaka",
      description:
        "International Business Promotion Center (IBPC) Osaka is an organization established by Osaka City in April 2003 to strengthen Osaka's trade, industry and communication activities with Asia and other cities around the world. \nIBPC Osaka also operates West Japan's largest exhibition center, \"INTEX Osaka.\"We also support national and international companies with their business expansion to Osaka city, such as location and settlement support, R&D base support services, etc. ",
      url: "https://www.ibpcosaka.or.jp/en/",
    },
    {
      subtitle: "Osaka Business Development Agency",
      city: "osaka",
      title: "Sansokan (Osaka Business Creation Center)",
      description:
        'The Osaka Business Creation Center, established by the City of Osaka in 2001 and operated by the Osaka Business and Development Association (OBDA), is a one-stop business support center for start-ups and small businesses. \nThe center\'s general consultation service, "Akinai Aid," provides support for overcoming business challenges regardless of industry or business stage. \nThe center also has a support desk for foreign business start-ups, which provides information on visas for business start-ups and provides application support. Support is provided through a variety of programs ranging from brushing up business ideas to raising funds.',
      url: "https://www.innovation-osaka.jp/startup-ecosystem/guide/",
    },
    {
      subtitle: "Osaka Business Development Agency",
      title: "MEBIC(Media business innovation center)",
      city: "osaka",
      description:
        'In order to revitalize the creative businesses that are concentrated in Osaka, we are working to create a community where creators working in Osaka can build "face-to-face relationships" with other creators and between creators and businesses, and to create an environment where new business and value can be created through competition and collaboration.',
      url: "https://www.mebic.com/media/pdf/pamphlet-en.pdf",
    },
    {
      subtitle: "kyoto wisdom industry creation center",
      title: "KYOTO STARTUP ECOSYSTEM",
      city: "kyoto",
      description:
        "This is a portal site that introduces a wide range of support menus from government agencies and private organizations for those who run start-up companies in Kyoto and those, who are planning to start a new business. \nWe have compiled a lot of useful events for those, who want to start a business in Kyoto and various support measures such as human resource development and fund procurement. \nMaps introducing Kyoto start-up companies, influencer interviews, coworking facilities, and a list of incubation facilities are also posted. If you are interested in starting a business in Kyoto, please take a look.",
      url: "https://kyotostartup.jp/ ",
    },
    {
      subtitle: "KYOTO Industrial Support Organization 21\nKyoto Prefecture",
      title: "Coworking co-vocation introduction site. Kyoto Startup Homebase",
      city: "kyoto",
      description:
        'Kyoto Startup Homebase introduces information on "working places" that are rich in characteristics in Kyoto Prefecture.\nIf you are thinking of starting a business in Kyoto, we will be very glad if you find your favorite base "working place" here. \nWe hope that Kyoto will become your "Homebase" (a place where you can work and feel comfortable).',
      url: "https://startuphomebase.kyoto/",
    },
    {
      subtitle:
        "JETRO Kyoto\nKyoto Prefecture\nKyoto City\nKyoto Research Park",
      title: "Health Venture Conference Kyoto(HVC KYOTO)",
      city: "kyoto",
      description:
        "HVC KYOTO is providing a key component of business planning for domestic and global healthcare startups, “Global Market Insight”. To let startups obtain “real voices” from the global market, HVC KYOTO made alliances with global healthcare companies, professional advisors, mentors, and trainers in the translational research and business planning. Our uniqueness and strength are providing training and an opportunity to pitch in “English” in front of “Global Companies”.",
      url: "https://www.krp.co.jp/hvckyoto/",
    },
    {
      subtitle:
        "Kyoto　City\nKyoto Research Park\nAdvanced Science, Technology & Management Research Institute of KYOTO（ASTEM）\nMonozukuri Ventures",
      title: "Kyoto Makers Garage",
      city: "kyoto",
      description:
        "Kyoto Makers Garage, or KMG as it is commonly known, is a makerspace marked by its independent spirit and DIY culture.Rapid-prototyping tools, such as 3D printers, CNC machines, a laser cutter and more, are available to the public.  KMG is a service of Monozukuri Ventures, a Kyoto-based VC firm that backs and supports both domestic and international hardware startups. The makerspace is also supported by Kyoto City and local business partners.",
      url: "https://kyotomakersgarage.com/",
    },
    {
      subtitle: "JETRO Kyoto\nKyoto Prefecture\nCity of Kyoto",
      title: "STARTUP VISA in KYOTO",
      city: "kyoto",
      description:
        "If you are a talented entrepreneur looking to grow a high impact startup in KYOTO, this is your chance to get a Startup Visa in Kyoto. \nUnder this system, foreign nationals who wish to start a business in Kyoto Prefecture can stay in Japan for the purpose of preparing to start a business. \nWe’ll guide you through the systems. ",
      url: "https://www.kyoto-obc.jp/startupvisa/",
    },
    {
      subtitle: "Kobe City",
      title: "Startup Visa",
      city: "hyogo_kobe",
      description:
        "The Kobe Startup Visa allows foreign nationals seeking the residence status of “Management / Manager” to enter and stay for up to one year in preparation for starting a business. At the time of application, an office isn't required to establish resident status, nor do you have to secure capital or hire staff at this point. Kobe City will certify your application, and a “Specific Activity (Start-up)” residence status will be issued.",
      url: "https://kobestartup.com/ecosystem/startup-visa/",
    },
    {
      subtitle: "Kobe City\nJETRO Kobe\nHyogo prefecture",
      title: "Business support in Kobe",
      city: "hyogo_kobe",
      description:
        "Kobe City, JETRO Kobe and Hyogo prefecture support your business with free temporary offices and several subsidies.",
      url: "https://global.kobe-investment.jp/english/assistance.php",
    },
    {
      subtitle: "KYOTO Industrial Support Organization 21\nKyoto Prefecture",
      title: "Angel Community",
      city: "kyoto",
      description:
        "Our Angel Advisors are made up of Kyoto manufacturing company managers and angel investors from Kyoto City, who provide management advice, mentoring and financial support, mainly to initial phase and early-stage deep technology startups in Kyoto Prefecture. We are a community that supports early-stage growth in a consistent and inclusive manner.",
      url: "https://www.ki21.jp/kkc/service/angelcommuen/",
    },
    {
      subtitle: "Kobe City",
      title: "Kobe Startup Hub and Global Mentorship Program",
      city: "hyogo_kobe",
      description:
        "Kobe City offers Tailor-made support for Japan-based founders and foreign startups ready to enter Japan through Kobe Startup Hub. Once you registered Kobe Startup Hub, community managers will provide support best suited to your needs.",
      url: "https://mentor.kobestartuphub.com/english/",
    },
    {
      subtitle: "Osaka City",
      title: "Osaka Top Runner Project",
      city: "osaka",
      description: `The Osaka Top Runner Development Project will support the refinement of projects aiming to commercialize products and services in the medical, nursing and health fields that are expected to become a new demand.\nIn addition, certified projects will be provided detailed individual support up to commercialization.`,
      url: "https://www.osaka-toprunner.jp/",
    },
    {
      subtitle: "Osaka City",
      title:
        "V:DRIVE (Venture Drive)\nShort-term Dispatch Program for Venture Companies",
      city: "osaka",
      description: `This program dispatches employees from large companies to provide training at venture companies in Osaka City for a designated period.  Constant support is provided from preparation, matching of companies, mentoring during the training period, and follow-up after the program is completed. The program aims to promote the mobility of human resources from large companies to venture companies in order to accelerate the growth of venture companies.`,
      url: "https://vdrive-osaka.com/",
    },
    {
      subtitle: "Osaka City",
      title: "Osaka City Innovation Creation Support Subsidy",
      city: "osaka",
      description: `By covering part of the cost of industry-academia collaborative R&D based on the research/technology seeds of universities, the program aims to promote efforts toward their practical application to create economic growth and innovation in the city.`,
      url: "https://www.city.osaka.lg.jp/keizaisenryaku/page/0000552784.html",
    },
    {
      subtitle: "Osaka City",
      title: "Business Creation Project Using Advanced Technologies such as 5G",
      city: "osaka",
      description: `This program provides one-stop comprehensive business support through public-private partnerships. Small and medium-sized enterprises and entrepreneurs who are trying to create new products and services that utilize the functions of "5G X LAB OSAKA" and cutting-edge technologies such as 5G, AI, IoT, and robot technology will be supported.`,
      url: "https://www.city.osaka.lg.jp/keizaisenryaku/page/0000522389.html",
    },
    {
      subtitle:
        "Osaka Prefecture\nOsaka City\nOsaka Chamber of Commerce and Industries",
      title: `Initiatives of "Demonstration Project Osaka"`,
      city: "osaka",
      description: `Osaka Prefecture, Osaka City, and the Osaka Chamber of Commerce and Industry have established a demonstration project promotion team in Osaka to create an environment conducive to innovative demonstration projects, create a virtuous cycle that generates new business in Osaka in anticipation of the 2025 Osaka-Kansai Expo, which has the concept, "A Laboratory for a Future Society."\nThe Osaka Chamber of Commerce and Industry serves as the secretariat, providing support such as offering the use of related public facilities and spaces, as well as factories and facilities of cooperating companies.`,
      url: "https://www.city.osaka.lg.jp/keizaisenryaku/page/0000520364.html",
    },
    {
      subtitle: "Sakai City\nSAKAISHINJIGYO INC. (S-Cube)",
      title: `Startup Demonstration Promotion Program`,
      description: `Sakai City calls for new business ideas to help solve problems in society or create innovation in the city. Support will be provided through the "Sakai Startup Trial Roundtable" to be set up in S-Cube, for the realization of startup businesses by providing opportunities for demonstration projects in Sakai City, including the Nakamozu and Semboku areas, as well as providing the costs associated with these demonstration projects.`,
      url: "https://www.s-cube.biz/roundtable",
      city: "sakai",
    },
    {
      subtitle:
        "Advanced Science, Technology & Management Research Institute of KYOTO (ASTEM)",
      title: `Social Innovation Laboratory Kyoto (SILK)`,
      description: `The Social Innovation Laboratory Kyoto (SILK) was established in April 2015 as a center for promoting the Kyoto City Social Innovation Cluster Initiative. The initiative aims to spread values different from excessive efficiency and competitive principles throughout Japan and the world by bringing together a wide variety of organizations and individuals, including citizens, businesses, NPOs, and universities, to take on the challenge of solving social issues in Kyoto.`,
      url: "https://social-innovation.kyoto.jp/",
      city: "kyoto",
    },
    {
      subtitle: "General Incorporated Association Kyoto Chiesangyo Sozonomori",
      title: `Kyoto Open Innovation Network (KOIN)`,
      description: `KOIN (Kyoto Open platform Network) is the nickname of the Open Innovation Cafe on the 3rd floor of the Kyoto Economic Center, which is operated by the Kyoto Chiesangyo Sozonomori, established by Kyoto Prefecture, Kyoto City and Kyoto's industrial community. KOIN is a place of co-creation for those who want to take a new step forward.\nThe wisdom and technology of Kyoto transcending fields and eras are gathered here and will help support your new step forward.`,
      url: "https://open.kyoto/",
      city: "kyoto",
    },
    {
      subtitle: "Kyoto Prefecture\nKyoto Institute of Invention and Innovation",
      title: `Kyoto Prefecture Startup Global Intellectual Property Support Desk`,
      description: `The Kyoto Prefecture Startup Global IP Support Desk was established to support the growth and development of startup companies, etc. aiming to expand globally by providing IP consultation, strategic planning, and support for overseas applications and infringement countermeasures, etc. by experts who have knowledge of various IP laws/regulations and knowhow to implement strategies in other countries.`,
      url: "https://www.kyotohatsumei.com/support_desk/index",
      city: "kyoto",
    },
    {
      subtitle: "Kyoto Prefecture",
      title: `Kyoto Acceleration Program`,
      description: `The program will provide an incubation program for large companies to support them in the planning and commercialization of new business plans. The support will be based on the issues and business ideas of the participating large companies, while changing their internal awareness and developing intrepreneurial human resources for the aim of creating carve-out startups from large companies in Kyoto in the future.`,
      url: "",
      city: "kyoto",
    },
    {
      subtitle: "Hyogo Prefecture",
      title: `Kigyo Plaza Hyogo (Kobe/Himeji/Amagasaki)`,
      description: `In order to increase the momentum of entrepreneurship and business startups in Hyogo Prefecture, "Kigyo Plaza Hyogo" has been established in three locations in Kobe, Himeji, and Amagasaki.`,
      url: "https://web.pref.hyogo.lg.jp/sr10/kigyoplaza.html",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Kobe City",
      title: `Urban Innovation KOBE`,
      description: `This project presents themes based on issues that the government has and conducts experiments in collaboration with startups. Selected teams and government officials will work together for approximately four months to build and demonstrate new services with the goal of full-scale implementation after the experiment.`,
      url: "https://urban-innovation-japan.com/",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Kobe City",
      title: `ANCHOR KOBE`,
      description: `ANCHOR KOBE was established in the Kobe Sannomiya Hankyu Building with the aim of promoting the creation of innovation and new partnerships through exchanges among startups, companies that are located in the Kobe Biomedical Innovation Cluster, research institutes/universities, and local manufacturing companies.`,
      url: "https://anchorkobe.com/",
      city: "hyogo_kobe",
    },
    {
      subtitle: "Kobe City",
      title: `Kobe x Engineer's Lab," a program to create engineers`,
      description: `This training program aims to produce engineers who can play an active role in the world. The program offers a programming salon that provides practical learning opportunities, a support system to help students improve their skills, and internship matching between students and companies.`,
      url: "https://kobe-engr-lab.studio.site/",
      city: "hyogo_kobe",
    },
    {
      subtitle:
        "Shiga Hatsu Seichou Sangyo Hakkutsu Ikusei Consortium (Shiga Consortium)",
      title: `Shiga Tech Planter`,
      description: `This is a business startup support program to foster researchers, engineers, and entrepreneurs who will change the world by leveraging the strengths of the region, such as the research seeds of science and engineering universities and manufacturing companies in Shiga Prefecture, and research results in water and environmental technologies related to Lake Biwa.\nThe program provides various types of support by working together to develop a roadmap necessary for commercialization. In addition, the "Shiga Tech Plan Grand Prix" is held as an event for participating teams to appeal for collaboration with companies, researchers, and others.`,
      url: "https://shiga-consortium.com",
      city: "shiga",
    },
  ],
};

const STARTUP_STATUS_JP = {
  0: [
    {
      subtitle: "大阪市\n大阪イノベーションハブ",
      title: "OIHスタートアップアクセラレーションプログラム(OSAP)",
      city: "osaka",
      description:
        "創業前後のスタートアップ企業が、起業経験者等からのアドバイスや、大企業・ベンチャーキャピタル等との連携により、事業成長の加速（アクセラレーション）をめざす取組。\n\n内容としてはメンター陣による個別面談、研修・ワークショップ、プログラム終盤に、プログラム受講者がベンチャーキャピタルや大企業向けにビジネスモデルやサービス内容を説明し、投資や事業提携の実施等を訴求するデモデイを実施する。2022年度下期募集で14期を数え、12期終了後の（2022年3月現在）資金調達額は132億円。",
      url: "https://www.innovation-osaka.jp/acceleration/",
    },
    {
      subtitle: "大阪府\n大阪イノベーションハブ",
      title: "スタートアップ・イニシャル・プログラムOSAKA　（SiO）",
      city: "osaka",
      description:
        "起業前の個人や起業後間もないスタートアップ企業を対象とした、事業立上げに必要な専門知識の支援プログラムです。\n1か月に2回以上の個別のメンタリングの他、既存企業（大企業・中堅・中小企業等）との連携・協業を促進するための取り組みとして、ピッチイベントやワークショップ等を実施し、・必要に応じて、銀行やVC、事業会社等への専門的な相談の機会も提供可能です。・およそ6か月間の支援を通じて、参加者の成長と事業プランのブラッシュアップを促します。",
      url: "https://sio.innovation-osaka.jp/",
    },
    {
      subtitle: "大阪府\n大阪イノベーションハブ",
      title: "大阪府スタートアップ発展支援プロジェクトRISING!",
      city: "osaka",
      description:
        "ミドルからレイター期のスタートアップ企業対象に先輩起業家が後輩起業家を支援するプログラム。メンタリング、テーマ別勉強会、大手経営者・経営幹部との交流会などを通じて、成長段階での課題をサポート。将来の大阪発のロールモデルとなる企業の発掘、成長支援を目的として、⽀援後３年以内に⽀援対象企業の半数以上が売上４倍以上、ユーザー数４倍以上、雇⽤２倍以上のいずれかを達成することを目標にしています。",
      url: "https://www.innovation-osaka.jp/rising/",
    },
    {
      subtitle: "大阪公立大学",
      title: "大阪公立大学ヘルステックスタートアップス",
      city: "osaka",
      description:
        "ヘルスケア分野で起業したい方、新規事業を起こしたい方を対象としたビジネス創出プログラムです。大阪公立大学のヘルスケア分野におけるニーズやシーズを活用しながら、ビジネスの組み立て方を実践的に学び、ヘルスケア分野の様々な専門家によるメンタリングにより、新たな製品・サービス開発をめざします。プログラムの最後にはビジネスプランを発表する「デモデイ」を実施し、協業パートナーの探索や資金調達に繋げていきます。",
      url: "https://www.chsi.osaka-cu.ac.jp/healthtech_startups/",
    },
    {
      subtitle: "大阪産業局（ソフト産業プラザ TEQS）",
      title: "テック・ビジネス・アクセラレータ　SUITCH",
      city: "osaka",
      description:
        "「StartUp」×「Innovation」×「TeCH」をキーワードに、テクノロジーでイノベーションを起こそうとするスタートアップの「スイッチ」を押すプログラム。それがテック・ビジネス・アクセラレータSUITCHです。\nブロックチェーン技術やメタバースを活用し、WEB3時代の新しいサービス開発をめざします。技術シーズをもとにビジネスアイデアを発想し、プログラムを通してブラッシュアップ。メンターや専門家のアドバイスのもと、アイデア探索からビジネスモデル構築まで一気通貫で取り組みます。",
      url: "https://teqs.jp/suitch2022",
    },
    {
      subtitle: "さかい新事業創造センター(S-Cube)",
      title: "アクセラレーションプログラム",
      city: "sakai",
      description:
        "コロナ禍発生以降、生活スタイル、ビジネスなど世間を取り巻く環境は大きく変化しました。今、これまでの仕組み・価値観を見直すこと、そして変化・発展させるイノベーティブな取り組みを行うヒトが強く求められています。本プログラムでは講座・セミナーと交流会・メンタリングを通じて、イノベーションに必要な知識とネットワークを提供します。自らの未来に新たな価値を生み出す取り組みをコミットし、仲間と視座を高めあい、共に新しい時代を切り開きましょう。",
      url: "https://www.s-cube.biz/acceleration",
    },
    {
      subtitle: "兵庫県\n神戸市",
      title: "SDGs CHALLENGE",
      city: "hyogo_kobe",
      description:
        "兵庫県・神戸市は、UNOPS Innovation Centre Japan(Kobe)と連携し、グローバルなSDGs課題解決を目指す共創プログラム「SDGs CHALLENGE」を実施しています。シード期のスタートアップや第二創業に取り組む中小・中堅企業の事業開発を支援します。",
      url: "https://sdgs-challenge.jp/",
    },
    {
      subtitle: "鳥取県",
      title: "TORIGGER（トリガー）",
      city: "tottori",
      description:
        '「TORIGGER（トリガー）」は、鳥取県とスタートアップ拠点「隼Lab.」が連携して行う""新事業創出プログラム""。\n「起業家（第２創業者含む）育成プログラム」と「アクセラレーター（スタートアップ支援者）育成プログラム」の２つのプログラムにより、鳥取県発の新しいビジネスの創出を志す方々をサポートする環境を醸成し、鳥取県内における「スタートアップ・エコシステム」の構築を目指します。',
      url: "https://www.pref.tottori.lg.jp/297602.htm",
    },
    {
      subtitle: "大阪産業局\n近畿経済産業局",
      title:
        "女性起業家応援プロジェクト\n「DISCOVER MYSELF」\n「ビジネスプラン発表会LED関西」",
      city: "osaka",
      description:
        "女性起業家応援プロジェクトは、近畿2府5県の起業をめざす女性や、起業後事業拡大をめざす女性、そして大企業と連携し新たな価値創出に挑戦するLED関西ファイナリスト（アンバサダー）など、あらゆるフェーズにいる女性起業家の成長をサポートし、関西の女性起業家を日本へ、世界へスケールアップさせていきます。",
      url: "https://discovermyself.jp/",
    },
    {
      subtitle: "兵庫県",
      title: "ポストコロナ再チャレンジ起業家育成事業",
      city: "hyogo_kobe",
      description:
        "起業への再挑戦を応援するため、日本全国から再チャレンジを目指す有望な起業家を集め、事業実現のためのアクセラレーションプログラムを実施します。また、起業における失敗談や再チャレンジ起業の課題・経験談等のセミナーも開催します。これらを通じて、参加者等による兵庫県内での再チャレンジ応援コミュニティの形成を図ります。",
      url: "",
    },
    {
      subtitle:
        "神戸市\n公益財団法人神戸医療産業都市推進機構\n（共催）㈱リバネス",
      title: "メドテックグランプリＫＯＢＥ",
      city: "hyogo_kobe",
      description:
        "創業前後のライフサイエンス・スタートアップ発掘と育成を目的としたアクセラレーションプログラム。\n民間企業等とのマッチングを目的としたデモデイを実施し、その後も伴走して成長を支援します。",
      url: "https://techplanter.com/medtech/2022/",
    },
    {
      subtitle:
        "神戸市\n公益財団法人神戸医療産業都市推進機構\n（パートナー企業・団体）\nバイエル薬品㈱\nシスメックス㈱\nアストラゼネカ㈱\n日本ベーリンガーインゲルハイム㈱\n京都大学医学研究科「医学領域」産学連携推進機構",
      title: "KANSAI Life Science Accelerator Program",
      city: "hyogo_kobe",
      description:
        "ライフサイエンス分野のスタートアップのグローバル展開をサポートするアクセラレーションプログラム。ピッチイベントの入賞企業は、米国カリフォルニア州のライフサイエンス領域に特化した支援団体California Life Sciences（CLS）による3か月にわたるメンタリングや、投資家・事業会社とのネットワークの機会を得られます。",
      url: "https://www.fbri-kobe.org/kbic/klsap/index.html",
    },
  ] as StatusType[],
  1: [
    {
      subtitle: "（一社）日本スタートアップ支援協会",
      title: "The JSSA ONLINE Meetup",
      city: "osaka",
      description:
        "最新のスタートアップ事情を把握できる大規模Meetup。ピッチやアワードなどを実施し、ウィズコロナ」「アフターコロナ」に立ち向かうスタートアップ企業を支援すべく、学びと気づき、企業同士の提携や、コネクションづくりになる場を提供しています。",
      url: "https://www.yumeplanning.jp/",
    },
    {
      subtitle: "さかい新事業創造センター(S-Cube)",
      title: "起業家育成キャンパス",
      city: "sakai",
      description:
        "通常のIM面談では、入居企業を対象にしていますが、ここでは、これから事業を始める方、アイデア段階の方を対象にし、事業計画の策定を中心に行います。アイデア段階の方には、考えている事業の継続性があるのか、成長性があるのかも併せて精査していきます。また、できた事業計画を基に、運転資金など資金調達を行い、創業（開業）できるところまで支援しています。",
      url: "https://www.s-cube.biz/",
    },
    {
      subtitle: "さかい新事業創造センター(S-Cube)",
      title: "経営実務勉強会",
      city: "sakai",
      description:
        "日頃の支援では、集中できないことも多く、より知識を深めるために、分野に特化した専門家から、基礎を学び、課題を相談、早期解決に向けた支援をしています。",
      url: "https://www.s-cube.biz/",
    },
    {
      subtitle: "さかい新事業創造センター(S-Cube)",
      title: "U-30若者スタートアップ支援事業",
      city: "sakai",
      description:
        "30歳までの学生などの若者向けのスタートアップ支援事業。ビジネスアイデアの創出をサポートするアイデアソンなどを実施しています。",
      url: "https://www.s-cube.biz/",
    },
    {
      subtitle: "徳島県\nとくしま産業振興機構",
      title: "平成長久館\n（起業力養成講座　他）",
      city: "tokushima",
      description:
        "起業力養成講座\n実業家やベンチャー起業家、銀行員、中小企業診断士といった各分野の専門家が創業に必要な基礎知識を解説します。またビジネスプラン作成実習では、中小企業診断士が実際の作成手法などをレクチャーします。\n徳島県、（公財）とくしま産業振興機構、徳島大学の連携講座です。",
      url: "https://www.our-think.or.jp/317758/",
    },
    {
      subtitle: "神戸市\n公益財団法人神戸医療産業都市推進機構",
      title: "ライフサイエンス・スタートアップ経営人材育成カリキュラム",
      city: "hyogo_kobe",
      description:
        "全国的に不足が課題である、ライフサイエンス・スタートアップの経営人材の育成を目的としたカリキュラム。実シーズを用いた国の補助金事業への申請模擬体験や資本政策、知的財産戦略など、スタートアップ経営者に必要な知識を身に付ける内容を提供（ＡＭＥＤ、ＪＳＴ、ＮＥＤＯ協力事業）。",
      url:
        "https://www.fbri-kobe.org/kbic/service/field05/support.php#support-sec05",
    },
    {
      subtitle:
        "神戸市\n公益財団法人神戸医療産業都市推進機構\n（共催）バイエル薬品㈱",
      title: "グローバル創薬開発ワークショップ",
      city: "hyogo_kobe",
      description:
        "創薬系スタートアップ経営者や起業を検討している研究者等を対象に、グローバル製薬企業における治療薬開発のプロセスを学ぶワークショップ（バイエル薬品㈱共催）。\nバイエル本社が独自に開発したプログラムを活用し、グローバル製薬企業における開発戦略や意思決定のプロセスを体験できます。",
      url: "https://www.fbri-kobe.org/event/detail.php?event_id=512",
    },
  ] as StatusType[],
  2: [
    {
      subtitle: "関西経済同友会",
      title: "関西ベンチャーフレンドリー宣言",
      city: "osaka",
      description:
        "「我々は、関西が独創的で世界に開かれたイノベーションの先進地となることを目指す。\nそのために、関西でベンチャーエコシステムが立ち上がりつつある今、我々が古くから培ってきたユニークな発想や「やってみなはれ」の文化に代表されるダイナミックな企業文化を継承し、新しいビジネスの立ち上げに奮闘する次代の経営者に寄り添い、支援する、ベンチャーフレンドリーな企業であることをここに宣言する。」既存企業とベンチャー企業の連携を深めていくことを目的に宣言したものです。現在、多くのベンチャー企業が、起業時に苦労する点として「顧客・販路の開拓」「技術に関する相談」「資金繰り」などとともに、既存企業に相談しに行っても「実績が無ければ門前払い」という大きな壁を挙げています。そこで、創業を目指す方やベンチャー企業の方々に向けて門戸をオープンにし、各種相談に対して積極的に対応させて頂く企業・団体として、96社が集まりました",
      url: "https://www.kansaidoyukai.or.jp/event/",
    },
    {
      subtitle: "神戸市",
      title: "グローバル・メンターシップ・プログラム",
      city: "hyogo_kobe",
      description:
        "起業経験・スタートアップ支援経験を持つコミュニティマネジャーが対象者のニーズに合わせ、国内外で活躍するメンターおよび支援プログラムのマッチングを行う支援事業。約60名のメンターによる個別メンタリングが無料で受けられるほか、公開セミナー、グループメンタリングも開催。",
      url: "https://kobestartuphub.com/faq-mentor-jp",
    },
    {
      subtitle: "徳島県\nとくしま産業振興機構",
      title: "とくしま産業振興機構　専門家派遣　速効経営助っ人事業",
      city: "tokushima",
      description:
        "常設の相談窓口に加え、様々な分野の専門家を派遣し、診断・助言を行う「速効経営助っ人事業」を実施しております。経営革新・向上を目指す中小企業や創業者等が抱える諸課題（経営全般、マーケティング、資金、法務、人事労務、税務、創業、ＩＴ、特許、販路開拓、ＩＳＯ認証取得等）に対し、専門家を派遣することが可能ですので、ご活用ください。",
      url: "https://www.our-think.or.jp/other/dispatch/",
    },
  ] as StatusType[],

  3: [
    {
      subtitle: "近畿経済産業局",
      title: "J-Startup KANSAI",
      city: "osaka",
      description:
        "関西発の有望なスタートアップ企業群を明らかにし、地域ぐるみで起業家を応援・支援する仕組みを構築。内閣府のスタートアップ・エコシステム拠点形成事業とも連動して、官民連携によるJ-Startup KANSAI企業を支援するコミュニティを構築します。事務局が中心となり、公的機関と民間企業が連携して集中支援を実施することで、選定企業の飛躍的な成長をサポートします。",
      url: "https://next-innovation.go.jp/j-startup-kansai/",
    },
    {
      subtitle: "堺市",
      title: "ベンチャー調達認定制度",
      city: "sakai",
      description:
        "新商品の生産又は新役務の提供により新たな事業分野の開拓を図る者を市長が認定し、認定事業者の初期需要創出及び信頼性向上による販路開拓を支援することで、本市の経済活性化を図るとともに、当該新商品等を市が随意契約による調達を可能とする制度です。",
      url:
        "https://www.city.sakai.lg.jp/sangyo/shienyuushi/chusho/busexp/tyoutatsu.html",
    },
    {
      subtitle: "公益財団法人京都高度技術研究所",
      title: "オスカー認定制度",
      city: "kyoto",
      description:
        "優れた事業計画（パワーアッププラン）により積極的に経営革新に取り組む中小企業を「オスカー認定」し、計画の実現に向けた継続的な支援を実施することで、京都経済の中核を担う中小企業を育成します。",
      url: "https://www.astem.or.jp/smes/oscar",
    },
    {
      subtitle: "公益財団法人京都高度技術研究所",
      title: "京都市ベンチャー企業目利き委員会",
      city: "kyoto",
      description:
        "次代の京都経済をリードするベンチャー企業を発掘、育成するため、起業を考えておられる皆さんの事業プランの事業性、技術・アイデアなどを「京都市ベンチャー企業目利き委員会」が評価します。 全国から素晴らしい事業プランが集まり、起業していただくことにより、経済活性化の原動力にしたいと考えています。",
      url: "https://www.venture-mekiki.jp/",
    },
  ] as StatusType[],
  4: [
    {
      subtitle: "大阪イノベーションハブ",
      title: "うめきたピッチ",
      city: "osaka",
      description:
        "スタートアップと大企業／VCを繋ぐピッチイベント\n" +
        "大阪イノベーションハブ（OIH）のレギュラーピッチイベント「うめきたピッチ」は、毎月テーマを設定し、スタートアップと大企業・大手中堅企業、ベンチャーキャピタルが接点を強く持ち、協業などに繋げるピッチイベントです。\n" +
        "大阪市、大阪府内外を問わず、OIHのプレイヤー会員（スタートアップ企業）が最新テクノロジーを駆使したプロダクトやサービスを紹介し、その後事業提携、資金調達実績が生まれています。",
      url: "https://www.innovation-osaka.jp/ja/service/pitch/umekita/",
    },
    {
      subtitle: "大阪イノベーションハブ",
      title: "OIH　スターターズ・ピッチ",
      city: "osaka",
      description:
        "アントレプレナーのための壁打ちピッチ！\n" +
        "起業を志すうえで、避けては通れない“ピッチ”。\n" +
        "ピッチを通じて資金調達や他社との事業提携を実現するには、ビジネスプランをしっかり立てること、自身のビジネスを簡潔にわかりやすく相手に伝えることが重要です。\n" +
        "本プログラムでは、「起業」という同じ志を持つ仲間と一緒にピッチのスキルや自分のビジネスプランをブラッシュアップしていきます。毎回、ビジネスにおいて専門知識や経験をもつ先輩起業家やベンチャーキャピタリストの方々がメンターとして、みなさんの事業計画についてアドバイスをします。",
      url: "https://www.innovation-osaka.jp/ja/service/pitch/starters/",
    },
    {
      subtitle: "大阪イノベーションハブ",
      title: "ミライノピッチ",
      city: "osaka",
      description:
        "IoT、ロボット、各種アプリなど情報通信分野に絞ったピッチイベント！ミライノピッチは、情報通信分野で起業をめざす方がお持ちのアイデアによる製品・アプリ・サービスの事業化を支援することを目的としたピッチイベントです。学生・一般部門より審査を通過した登壇者が、7分間のピッチコンテストに挑むイベントです。本イベントは、総務省、国立研究開発法人情報通信研究機構共催の「起業家甲子園・起業家万博」の近畿地区予選としての位置付けでもあり、成績優秀者を全国大会となる「起業家甲子園・起業家万博」へ送り出すこととしています。",
      url: "https://www.innovation-osaka.jp/ja/service/pitch/mirainno/",
    },
    {
      subtitle: "デロイトトーマツベンチャーサポート",
      title: "モーニングミートアップ",
      city: "osaka",
      description:
        "ベンチャー企業が大企業・VC・メディア等に向けピッチを行うプログラム。IPO(株式上場)を志向するベンチャーの成長促進、大企業との事業提携、大企業のオープンイノベーション創出をめざしています。\n主催はデロイト トーマツ ベンチャーサポート株式会社。",
      url: "https://www.innovation-osaka.jp/ja/service/pitch/morning-meet-up/",
    },
    {
      subtitle: "大阪イノベーションハブ",
      title: "GET IN THE RING OSAKA",
      city: "osaka",
      description:
        "2012年にオランダで始まったスタートアップのピッチコンテスト。世界100か国以上で地域大会が行われています。大阪イノベーションハブでは、例年日本大会を開催しており、優勝者には世界大会への挑戦権が与えられます。\n本物のリング上で繰り広げられる臨場感あふれるピッチバトルです。究極の英語プレゼン“エレベーターピッチ”で世界にPR。グローバル展開を志す他の登壇者をはじめ、チャンレンジャーを応援するスポンサーやVCとのネットワークを構築できます。",
      url: "https://www.innovation-osaka.jp/gitr/",
    },
    {
      subtitle:
        "JETRO(独立行政法人日本貿易振興機構)\n京都府\n京都市\n京都リサーチパーク株式会社",
      title: "Health Venture Conference Kyoto(HVC KYOTO)",
      city: "kyoto",
      description:
        "HVC KYOTOは、ヘルスケア分野で革新的な技術を持つベンチャーの、グローバルな事業展開をサポートするヘルスケア特化型の英語ピッチ＆パートナリングプラットフォームの先駆けです。日本から世界への飛躍を目指すイノベーターを支援するコミュニテイを、私達と共に作りませんか！",
      url: "https://www.krp.co.jp/hvckyoto/",
    },
    {
      subtitle: "和歌山県",
      title: "スタートアップ創出支援チームとのマッチングイベント",
      city: "wakayama",
      description:
        "和歌山県内で創業・第二創業を目指す方や、和歌山県の地域資源を活用した新規事業を行おうとする方を対象にピッチイベントを開催します。ピッチイベントにご参加いただいた方には、投資会社、大企業、金融機関などで構成される「スタートアップ創出支援チーム」が事業の展開をサポートします。",
      url: "https://www.pref.wakayama.lg.jp/prefg/063100/sougyo.html",
    },
    {
      subtitle: "京都産業２１",
      title: "エンジェルコミュニティ交流会",
      city: "kyoto",
      description:
        "京都を代表するものづくり企業経営者、および京都ゆかりのエンジェル投資家からなるエンジェルアドバイザーで形成され、主に京都府内のシード・アーリー期のディープテック型スタートアップを対象に経営アドバイス、メンタリングや資金支援を行い、早期成長を継続的・包括的に応援するコミュニティです。",
      url: "https://www.ki21.jp/kkc/service/angelcommu/",
    },
  ] as StatusType[],
  5: [
    {
      subtitle: "大阪産業局",
      title: "大阪イノベーションハブの運営",
      city: "osaka",
      description:
        "大阪イノベーションハブは、2013年に大阪市が設置した、世界に挑戦する起業家や技術者が集まるイノベーション創出拠点です。「大阪から世界へ」をテーマに、新たな事業の創出やスケールアップにつながるイベント・プログラム（年間約250回）を開催しています。そして、多様な人や企業、アイデアの交流を通して、コミュニティの形成やビジネスプランの事業化をサポート。「起業家を生み、育て、成功者にし、成功者が次の成功者を生む、関西における循環システム（イノベーションエコシステム）」の構築を推進しています。",
      url: "https://www.innovation-osaka.jp/ja/",
    },
    {
      subtitle: "大阪産業局",
      title: "ソフト産業プラザ TEQSの運営",
      city: "osaka",
      description:
        "「技術で課題解決する人」を対象とした、テクノロジー・ビジネスの支援拠点です。施設内の5G X LAB OSAKAでは、5G通信を使った専用の開発環境で、製品・サービスの開発や検証が可能。\nアクセラレーション・プログラムによるビジネスを「創る」サポートや、インキュベーション施設による「育成」、実証実験支援による「実証」サポートなどを提供。、ビジネス開発から育成、実証まで、一気通貫による総合的なサポートを提供しています。3Dプリンタやレイザーカッターも完備。",
      url: "https://teqs.jp/",
    },
    {
      subtitle: "大阪産業局",
      title: "クリエイティブネットワークセンター大阪 メビック",
      city: "osaka",
      description:
        "大阪で活動するクリエイターの情報発信、ネットワークづくり、ビジネスマッチング、人材育成に取り組むクリエイター支援施設です。クリエイターとクリエイティブやデザインを活用したい企業等との交流・連携・協働をサポートしています。",
      url: "https://www.mebic.com/",
    },
    {
      subtitle: "大阪産業創造館",
      title: "立志庵",
      city: "osaka",
      description:
        "事業プラン作成から事業開始までトータルサポート。ワークショップや個別面談、勉強会を通じて起業の課題解決を支援。同期とともに、事業計画や課題事項を検討・ブラッシュアップし、6か月で起業を実現させるプログラム。",
      url: "https://www.sansokan.jp/akinai/risshian/",
    },
    {
      subtitle: "さかい新事業創造センター(S-Cube)",
      title: "さかい新事業創造センター(S-Cube)",
      city: "sakai",
      description:
        "地下鉄御堂筋線「なかもず駅」から徒歩4分ほどの所に位置するS-Cube、創業準備デスクも含めると約50社が入居するインキュベーション施設です。\n" +
        "スタートアップ・ベンチャー企業のための充実した支援プログラムやミーティングできるオープンスペースがあります。業種や業歴、想い、行先も様々な彼らが居ることで、切磋拓磨できる環境がここにはあります。",
      url: "https://www.s-cube.biz/",
    },
    {
      subtitle: "独立行政法人中小企業基盤整備機構",
      title: "京大桂ベンチャープラザ(北館・南館)",
      city: "kyoto",
      description:
        "京大桂ベンチャープラザでは、“大学発技術シーズの産業化”や“新事業の創出”を目指す方々の成長を応援しています。",
      url: "https://www.smrj.go.jp/incubation/kkvp/index.html",
    },
    {
      subtitle: "独立行政法人中小企業基盤整備機構",
      title: "クリエイション・コア京都御車",
      city: "kyoto",
      description:
        "クリエイション・コア京都御車は、「産・学・官」のネットワークを活かし、ウエルネス分野において新たな事業展開を図ろうとされるベンチャー及び中小企業を支援するインキュベーション（起業家育成）施設です。",
      url: "https://www.smrj.go.jp/incubation/cckm/index.html",
    },
    {
      subtitle: "独立行政法人中小企業基盤整備機構",
      title: "D-egg(同志社大学連携型起業家育成施設)",
      city: "kyoto",
      description:
        "未来の新産業を創る。同志社発インキュベーション施設。D-eggは、地域に根ざした活動を通じ、産学官連携の促進、新産業の促進から、地域経済を発展させ社会に貢献する活動をしています。",
      url: "https://www.smrj.go.jp/incubation/d-egg/index.html",
    },
    {
      subtitle: "株式会社けいはんな",
      title: "京都府けいはんなベンチャーセンター",
      city: "kyoto",
      description:
        "京都府では、飛躍的な成長の可能性を秘めているベンチャー企業の皆さんを支援するため、平成12年6月より、「新産業創出都市」をめざす関西文化学術研究都市・けいはんなプラザにおいて、新産業創出・ベンチャー育成の拠点となるインキュベート・ルームを開設しています。",
      url: "https://www.keihanna-plaza.co.jp/office-labo/incubate-room/",
    },
    {
      subtitle: "宇治市",
      title: "宇治ベンチャー企業育成工場",
      city: "kyoto",
      description: "京都発ものづくりベンチャー企業の未来を支援します。",
      url: "https://www.city.uji.kyoto.jp/site/vif/",
    },
    {
      subtitle: "特定非営利活動法人京都西陣町家スタジオ",
      title: "西陣産業創造會舘",
      city: "kyoto",
      description:
        "NPO法人京都西陣町家スタジオが、京都府、NTT西日本と連携して運営する起業を目指す人や創業間もないベンチャー企業、フリーランスで働く小規模事業主の方々を支援するためのビジネスインキュベーションセンターです。",
      url: "https://www.nishijin-bic.kyoto/",
    },
    {
      subtitle: "徳島県\nとくしま産業振興機構",
      title: "とくしまSOHOプレイス",
      city: "tokushima",
      description:
        "雇用の創出、新産業の創出等を促進するため、 ITを活用したSOHO事業者等を対象に小規模オフィスを提供し、スタートアップ期にあるチャレンジャーを総合的に支援します。",
      url: "https://www.our-think.or.jp/?p=2246",
    },
    {
      subtitle: "徳島県\n徳島県立工業技術センター",
      title: "貸工場：起業家支援施設（明日葉工場）",
      city: "tokushima",
      description:
        "アイデアや技術を持ち、これから製造業を創業しようとする方又は製造業を創業して５年未満の方を対象に工場仕様の部屋（起業家支援施設「明日葉工場」）をお貸しています。この施設は工業技術センターに併設しているため、必要に応じ工業技術センターからの技術支援等を受けることもできます。",
      url: "https://www.itc.pref.tokushima.jp/01_service/06facility_03.shtm",
    },
    {
      subtitle: "神戸市\n神戸都市振興サービス㈱",
      title: "スタートアップ・クリエイティブラボ（SCL)",
      city: "hyogo_kobe",
      description:
        "創業前後のスタートアップが研究開発を進める拠点。研究開発に必要な設備を予め備え、初期投資を抑えて利用できるシェア型のウェットラボや、登記可能なシェアオフィスを完備。入居スタートアップは、神戸医療産業都市の人的ネットワークや事業化に資する支援施策が活用可能です。\n神戸市による利用料補助有り。",
      url: "https://kobe-scl.com/",
    },
  ] as StatusType[],
  6: [
    {
      subtitle: "大阪府\n大阪産業創造館",
      title: "大阪起業家グローイングアップ",
      city: "osaka",
      description:
        "経済・社会の新陳代謝を促し、大阪経済の持続的な成長を実現するため、将来の大阪経済を担う有望な起業家を発掘し、その成長を着実に支援する大阪府の事業です。ビジネスプランコンテストを中心に実施。",
      url: "https://www.obda.or.jp/jigyo/growingup.html",
    },
    {
      subtitle: "堺市",
      title: "インキュベーション施設入居者支援補助",
      city: "sakai",
      description:
        "インキュベーション施設（S-Cube）の入居者の起業及び新規事業展開等を促進し、地域経済の活性化を図ることを目的とし、賃貸借契約を締結して施設に入居する個人又は法人で、具体的な事業計画を有する者を対象に、賃料の一部を補助する制度です。",
      url:
        "http://www.city.sakai.lg.jp/sangyo/shienyuushi/sogyo/sozocenter.html",
    },
    {
      subtitle: "和歌山県",
      title: "和歌山県ベンチャー企業誘致制度",
      city: "wakayama",
      description:
        "和歌山県内にオフィスや工場などを賃借もしくは建設するベンチャー企業に対し、奨励金及び補助金を交付します。",
      url: "https://www.pref.wakayama.lg.jp/prefg/063100/d00204783.html",
    },
    {
      subtitle: "和歌山県",
      title: "先駆的産業技術研究開発支援事業",
      city: "wakayama",
      description:
        "和歌山県内の企業を対象とし、海外でも高いニーズが見込まれる先駆的な産業技術の研究開発や社会実装をするためにかかる事業費の一部を補助します。",
      url: "https://www.pref.wakayama.lg.jp/prefg/063100/senku.html",
    },
    {
      subtitle: "兵庫県",
      title: "成長産業育成のための研究開発支援事業（旧COEプログラム）",
      city: "hyogo_kobe",
      description:
        "兵庫県では、次世代産業を中心とした成長産業分野の育成を図るため、比較的初期段階にある産学官連携による共同研究を支援する提案公募型の研究補助制度「成長産業育成のための研究開発支援事業（旧COEプログラム）」を実施しています。",
      url: "https://web.pref.hyogo.lg.jp/sr09/ie03_000000002.html",
    },
    {
      subtitle: "兵庫県",
      title: "IT戦略推進事業",
      city: "hyogo_kobe",
      description:
        "県内の情報通信産業の振興や地域の活性化を図るため、IT関連の事業所を開設するIT関連事業者に経費の一部を補助しています。",
      url: "https://web.pref.hyogo.lg.jp/sr10/tashizen/index.html",
    },
    {
      subtitle: "兵庫県",
      title: "コワーキングスペース開設支援事業",
      city: "hyogo_kobe",
      description:
        "地域における起業拠点を創出するとともに、テレワーク・副業等の多様な働き方に対応するため、起業家等を対象としたコワーキングスペースを新たに開設する事業者等に経費の一部を補助します。",
      url: "https://web.pref.hyogo.lg.jp/sr10/koudoit.html",
    },
    {
      subtitle: "兵庫県",
      title: "起業家支援事業",
      city: "hyogo_kobe",
      description:
        "起業を促進するため、県内で起業・第二創業等を目指す起業家に対し、事業の立ち上げにかかる経費の一部を補助します。",
      url: "https://web.hyogo-iic.ne.jp/kigyo",
    },
    {
      subtitle: "鳥取県",
      title: "鳥取県起業創業トライ補助金",
      city: "tottori",
      description:
        "鳥取県において、新たな事業アイデア、技術又はノウハウ等を活用し、革新的な起業創業を実施しようとする起業者に対して、創業前又は創業後の事業実施に必要な経費の一部を補助します。",
      url: "https://www.pref.tottori.lg.jp/277668.htm",
    },
    {
      subtitle: "鳥取県",
      title: "とっとり先駆型ラボ誘致・育成補助金",
      city: "tottori",
      description:
        "新規性・革新性の製品・サービス開発や、大都市圏等から企業の機能及び業務の一部を鳥取県内に移転する事業をはじめとする「先駆的事業」を行う企業を対象として、立地場所の事前調査からサテライトオフィスの設置、さらに常設の研究開発拠点の設置まで幅広く最長４年にわたり支援します。 ",
      url: "https://www.pref.tottori.lg.jp/294244.htm",
    },
    {
      subtitle: "鳥取県",
      title: "鳥取県事業承継支援補助金（移定住・創業者支援タイプ）",
      city: "tottori",
      description:
        "鳥取県内中小企業者から、移定住者又は創業者が事業承継・事業引継ぎを行う場合の、新たな事業開始に係る必要な設備等導入などの初期投資を支援します。",
      url: "https://www.pref.tottori.lg.jp/273822.htm",
    },
    {
      subtitle: "徳島県\nとくしま産業振興機構",
      title: "わくわく創業支援補助金",
      city: "tokushima",
      description:
        "公募及び審査会を経て、県内で社会的課題解決のために、新たに創業する者に対し、必要な経費の一部を補助します。\n（補助上限２，０００千円、補助率１／２）",
      url: "https://www.our-think.or.jp/316816/9",
    },
    {
      subtitle: "徳島県",
      title: "ふるさと起業家支援プロジェクト",
      city: "tokushima",
      description:
        "知事の認定を受けた事業を対象にクラウドファンディングで寄附を募り、集まった資金に県が追加支援を行った金額を補助します。",
      url: "https://www.pref.tokushima.lg.jp/furusato-ouen/",
    },
    {
      subtitle: "兵庫県",
      title: "ひょうご農商工連携ファンド助成事業",
      city: "hyogo_kobe",
      description:
        "兵庫県内の農林水産物などの地域資源を生かした、魅力ある農業ビジネスの実現や、市場のニーズに適応した新商品、新サービスの開発等の実現のため、中小企業者等と農林漁業者の連携体が取り組む事業に対し事業費の一部を補助します。",
      url: "https://web.hyogo-iic.ne.jp/kigyo/renkeifund",
    },
    {
      subtitle: "兵庫県",
      title: "ポストコロナ・チャレンジ支援事業",
      city: "hyogo_kobe",
      description:
        "コロナ禍により表出したデジタル化・非接触化などの新たな社会課題に対して、革新的な発想や技術に基づき課題解決に取り組む、スタートアップをはじめとする中小企業等の起業または新ビジネス創出を支援します。",
      url: "https://web.hyogo-iic.ne.jp/kigyo/postcorona",
    },
    {
      subtitle: "神戸市",
      title: "イノベーション拠点立地促進補助",
      city: "hyogo_kobe",
      description:
        "インキュベーション機能を備えたコワーキングスペースの開設を促進するため、建物改修費や事務機器取得費など最大650万円を補助。",
      url: "https://kobe-investment.jp/system/startup/",
    },
    {
      subtitle: "神戸市",
      title: "スタートアップ補助",
      city: "hyogo_kobe",
      description:
        "神戸市に新たに事業所を開設するスタートアップに対し、上限3年間で最大1,300万円を補助。建物改修費、事務機器取得費、オフィス賃料、通信回線利用料、人件費が対象。",
      url: "https://kobe-investment.jp/system/startup/",
    },
    {
      subtitle: "神戸市",
      title: "オフィス賃料補助",
      city: "hyogo_kobe",
      description:
        "神戸市に市外から進出する企業に対し、オフィス賃料の1/4（IT関連企業は1/2）を基本的には3年間補助（賃借面積1500㎡以上の場合は5年）。雇用に対し一人当たり最大120万円を補助する雇用加算あり。",
      url: "https://kobe-investment.jp/system/office/",
    },
    {
      subtitle: "神戸市\n公益財団法人神戸医療産業都市推進機構",
      title: "神戸ライフサイエンスギャップファンド",
      city: "hyogo_kobe",
      description:
        "創業前後のスタートアップが持つ技術の社会実装の可能性を実用化前に検証するための補助金制度（限度額：500万円、補助率10分の10）。\n神戸に拠点があるスタートアップ及び神戸で起業予定の個人が対象で、事業化に資する研究費、試作品作成費や知的財産関連費用等を補助します。",
      url: "https://www.fbri-kobe.org/cluster/support/jyosei1.php",
    },
  ] as StatusType[],
  7: [
    {
      subtitle: "京都府\n京都市\n一般社団法人京都知恵産業創造の森",
      title: "京都スタートアップエコシステム \nKYOTO STARTUP ECOSYSTEM",
      city: "kyoto",
      description:
        "京都においてスタートアップ企業を経営されている方、創業予定の方を対象にして、行政機関や民間団体等の支援メニューを幅広く紹介するポータルサイトです。\n" +
        "京都で起業を目指す方にとって有益なイベントや人材育成や資金調達等の各種支援施策を数多く取りまとめております。\n" +
        "京都のスタートアップ企業を紹介する地図やインフルエンサーのインタビュー、コワーキング施設、インキュベーション施設の一覧なども掲載されております。京都での起業に関心がある方はぜひご覧ください。",
      url: "https://kyotostartup.jp/ ",
    },
    {
      subtitle: "徳島県\nとくしま産業振興機構",
      title: "とくしま・スタートアップ・プラットフォーム",
      city: "tokushima",
      description:
        "徳島県で「起業を目指す方」や「新規事業の立ち上げを目指す企業の担当者・大学の研究者」などが集い、サポーターが連携支援するコミュニティです。",
      url: "https://www.our-think.or.jp/?mode=detail&p=307755",
    },
    {
      subtitle: "徳島県\nとくしま産業振興機構",
      title: "創業促進・あったかビジネス支援事業",
      city: "tokushima",
      description:
        "「創業促進・あったかビジネス支援事業」は、特産品や文化等の「徳島県特有の資源」や個人のユニークな「能力・経験等」を活かした優れた創業計画を規模や業種にかかわらず広く「あったかビジネス」として計画認定し、地域に根ざした創業を県や関係機関が支援するものです。\n起業を目指す方は一度ご検討ください。\n随時、お申込みを受け付けております。",
      url: "https://www.our-think.or.jp/?mode=detail&p=2229",
    },
  ] as StatusType[],
  8: [
    {
      subtitle: "京都商工会議所",
      title: "京都スタサポ",
      city: "kyoto",
      description:
        "京都市内で創業したい方を応援する京都商工会議所が運営する情報サイトです。創業に関心のある人やこれから創業準備に取り掛かる人へ、創業のための基礎的な知識やお役に立つセミナー情報をお届けしています。",
      url: "http://www.stasapo.kyoto/",
    },
    {
      subtitle: "京都市",
      title: "京都市企業誘致サイト“Kyo-working（京ワーキング）”",
      city: "kyoto",
      description:
        "京都市への企業誘致に関する基本情報（立地補助や立地情報等）はじめ、有識者を招いたトークイベントのレポートや、「Kyo-working｜京ワーキング」を実践する京ワーカーのインタビューなど、京都の魅力を発信する様々なコンテンツを展開しています。",
      url: "https://kyo-working.city.kyoto.lg.jp/",
    },
    {
      subtitle: "神戸市",
      title: "KOBE STARTUP HUB",
      city: "hyogo_kobe",
      description:
        "神戸市内外に立地する様々な資産（人・場所・プログラム・VC・各種機関他）を可視化し、起業家と支援者が双方向で交流できるSNS機能を備えたポータルサイトです。神戸市内外の様々な起業家支援情報を集積し、交流を促すことで、エコシステムを形成し、多様な起業家の支援を目指します。",
      url: "https://kobestartuphub.com/",
    },
  ] as StatusType[],
  9: [
    {
      subtitle:
        "京都ものづくり支援協議会（京都市、京都リサーチパーク株式会社、公益財団法人京都高度技術研究所）\nMonozukuri Ventures",
      title: "Kyoto Makers Garage",
      city: "kyoto",
      description:
        "Kyoto Makers Garege(KMG)は、京都市、京都リサーチパーク、ASTEM、またMonozukuri Venturesが運営するメーカースペースです。KMGには3Dプリンター、CNC、レーザーカッター等のラピッドプロトタイピング用ツールが整備されており、全マシンのトレーニングも受けられるため、個人から起業家まで誰でも利用可能な場所となっています。またKMGではIoTやハードウェアに関する様々なワークショップやイベントも開催しており、京都のモノづくりカルチャーの裾野を広げることを目指しています。",
      url: "https://kyotomakersgarage.com/",
    },
  ] as StatusType[],
  10: [
    {
      subtitle: "日本ベンチャーキャピタル株式会社",
      title: "京大ベンチャーNVCC2号投資事業有限責任組合",
      city: "kyoto",
      description:
        "当ファンドは京都大学にある研究成果の起業化を促進し、京都大学に関連するベンチャー企業等を多面的に支援し、企業の成長を促進し、大学発ベンチャー企業の創出・育成と産学連携の推進により、日本経済の活性化に寄与することを目的にしております。",
      url: "https://www.nvcc.co.jp/",
    },
    {
      subtitle: "京都大学イノベーションキャピタル株式会社",
      title:
        "イノベーション京都 2021 投資事業有限責任組合KYOTO－iCAP2 号ファンド",
      city: "kyoto",
      description:
        "KYOTO－iCAP2号ファンドの調達額は181億4000万円です。主に京都大学の研究成果を活用するベンチャー企業へ投資しその成長を支援していきますが、一部資金については京大以外の国立大学発ベンチャーの支援にも活用します。",
      url:
        "https://www.kyoto-unicap.co.jp/icap/wp-content/uploads/Second-Fund_PressRelease_20210112.pdf",
    },
    {
      subtitle:
        "京都市\nフューチャーベンチャーキャビタル株式会社\n京都信用金庫\n京都中央信用金庫\n京都リサーチパーク\n日本政策金融公庫京都支店国民生活事業",
      title:
        "京都市スタートアップ支援１号ファンド\n京都市スタートアップ支援２号ファンド",
      city: "kyoto",
      description:
        "本ファンドは、ファンド出資者以外に京都市および株式会社日本政策金融公庫とも連携し、京都市内に拠点を置いている若手起業家や創業期のベンチャー企業、第二創業に取組む企業などへの投資と投資後の支援による地元産業の育成を行っています。",
      url: "https://www.city.kyoto.lg.jp/sankan/page/0000275652.html",
    },
    {
      subtitle: "京都信用金庫\nフューチャーベンチャーキャピタル株式会社",
      title: "イノベーションCファンド",
      city: "kyoto",
      description:
        "当ファンドは、京都府を中心とした京都信用金庫営業エリア内で、独創的な技術、サービスやビジネスモデルで地域経済の活性化に資する未上場企業へファンド資金を提供することで、当該企業の成長および社会課題解決等の実現に寄与することを目的としております。",
      url: "https://www.kyoto-shinkin.co.jp/whatsnew/pdf2018/n18-0110.pdf",
    },
    {
      subtitle: "京都銀行",
      title: "京銀輝く未来応援ファンド２号",
      city: "kyoto",
      description:
        "京都銀行では、地域経済の活性化や地域創生に貢献するべく、将来有望な創業企業やベンチャー企業、中小企業を支援する「京銀輝く未来応援ファンド2号投資事業有限責任組合」を設立いたしました。本組合は、成長の期待できる企業に対し、地域の中核企業に成長するまでに必要な資金の投資はもちろんのこと、資金面以外からも様々な経営支援を行うことを事業の目的としております。",
      url: "https://www.kyotobank.co.jp/houjin/support/grow.html",
    },
    {
      subtitle: "京都中央信用金庫",
      title: "中信ベンチャー・投資ファンド5号",
      city: "kyoto",
      description:
        "地元有望ベンチャー企業の発掘・育成を継続するため、新たな投資ファンドを設立することといたしました。当金庫の本部組織再編として2017年11月に新設した「地域創生部」との密な連携により、ライフステージが創業期から成長期にかけての企業を中心に積極的な育成支援を行ってまいります。",
      url: "https://www.chushin.co.jp/cms_source/files/180509_news.pdf",
    },
    {
      subtitle: "兵庫県\n神戸市",
      title: "ひょうご神戸スタートアップファンド",
      city: "hyogo_kobe",
      description:
        "兵庫県・神戸市は、経済の持続的成長を目指すため、社会にイノベーションを起こし得るスタートアップの集積・育成を支援する取り組みを進めており、さらなる地域経済の活性化と県内産業の競争力の向上を図るため、民間企業等と連携し、飛躍的な成長が見込まれるスタートアップへの投資を行う「ひょうご神戸スタートアップファンド」を設立しています。",
      url: "https://web.hyogo-iic.ne.jp/kigyo/startup",
    },
  ] as StatusType[],
  11: [
    {
      subtitle: "徳島県",
      title:
        "創業者無担保資金\n【あったかビジネス支援枠】\n（あったかビジネス支援枠：女性創業者）",
      city: "tokushima",
      description:
        "運転・設備資金として３５，０００千円以内で融資が受けられます。\n※あったかビジネス事業者計画認定事業者は、通常より有利な融資利率、保証料率で借入ができます。",
      url:
        "https://www.pref.tokushima.lg.jp/jigyoshanokata/sangyo/shokogyo/5015570/",
    },
    {
      subtitle: "鳥取県",
      title: "創業支援資金",
      city: "tottori",
      description:
        "鳥取県内金融機関では、創業等に係る事業の実施のため必要となる運転資金及び設備資金の長期・低利融資（創業支援資金）をご用意しております。",
      url: "https://www.pref.tottori.lg.jp/244761.htm",
    },
  ] as StatusType[],
  12: [
    {
      subtitle: "徳島県",
      title: "徳島県お試し発注事業",
      city: "tokushima",
      description:
        "県内企業が開発した新商品・サービス等を徳島県が率先購入し、官公庁での受注実績をつくるとともに、一定期間モニタリング調査を行い、結果を企業に還元することで商品等の改良につなげ、販路開拓を支援します。",
      url:
        "https://www.pref.tokushima.lg.jp/jigyoshanokata/sangyo/shokogyo/5037416",
    },
    {
      subtitle: "鳥取県（運営：公益財団法人鳥取県産業振興機構）",
      title: "とっとり価値協創型オープンイノベーションプラットフォーム",
      city: "tottori",
      description:
        "公益財団法人鳥取県産業振興機構では「とっとり価値協創型オープンイノベーションプラットフォーム」を立ち上げ、次の時代を見据えた“新分野進出”や“事業再構築”を目指す鳥取県内企業と全国のスタートアップ等企業のマッチング・協業促進を通じて、プロジェクト組成から新規事業化までを伴走サポートします。",
      url: "https://www.pref.tottori.lg.jp/298050.htm",
    },
  ] as StatusType[],
  13: [
    {
      subtitle: "Kansai Future Summit 2021 運営事務局",
      title: "関西フューチャーサミット",
      city: "osaka",
      description:
        "関⻄から世界を席巻する企業を輩出し、世界に認知され、世界と繋がるイノベーション地域にするために、産官学の関⻄のリーダーが集い、ネットワークを構築することで新たな産業発展に寄与するきっかけづくりを⽬指します。「おもろい⼈間が集まり、おもろいつながりを創る 」をテーマに、「業界初・業界⼀などおもしろい⼈たちのネットワーク」を構築を⽬指す、関西の起業家による起業家のためのサミット。",
      url: "https://kansai-future.com/",
    },
    {
      subtitle: "大阪市\n都市活力研究所\nジェトロ大阪本部\n大阪産業局",
      title: "国際イノベーション会議「Hack Osaka」",
      city: "osaka",
      description:
        "年一度開催される国際イノベーション会議「Hack Osaka」。起業家や投資家、大企業、学生、自治体など様々なプレイヤーがエコシステムの主役として参画し、有機的に繋がることで、次々とイノベーションを巻き起こすHack Osaka Movementを発信するとともに、2025年の大阪・関西万博等に向けて、グローバルに活躍するスタートアップに対する大阪の吸引力の向上をめざします。",
      url: "https://www.innovation-osaka.jp/hackosaka/",
    },
    {
      subtitle: "大阪商工会議所\n大阪外国企業誘致センター（O-BIC)",
      title: "グローバル・イノベーション・フォーラムOsaka（GIF) 2021",
      city: "osaka",
      description:
        "大阪商工会議所（OCCI)と大阪外国企業誘致センター（O-BIC)が主催する、国内外のスタートアップや先端技術・イノベーティブな取り組みを進める企業が集うオンライン展示会・商談会です。\n" +
        "本年はライブ・ピッチやオンライン・シンポジウムも開催するなど、プログラムも拡大。\n" +
        "GIFは世界の先進スタートアップやパートナーとの出会いの場、「大阪発」の新たなビジネス機会を創出します。",
      url: "https://gif.osaka.cci.or.jp/",
    },
    {
      subtitle: "大阪市",
      title: "大阪トップランナー育成事業",
      city: "osaka",
      description:
        "大阪トップランナー育成事業では、医療・介護・健康分野等において、新たな需要の創出が期待できる製品・サービスの事業化に向けてプロジェクトのブラッシュアップをサポートします。\nさらに認定されたプロジェクトは、きめ細やかな個別支援を行い、市場化までを徹底的にサポートします。 ",
      url: "https://www.osaka-toprunner.jp/",
    },
    {
      subtitle: "大阪市",
      title:
        "V:DRIVE（ベンチャー・ドライブ）\nベンチャー企業への短期派遣プログラム",
      city: "osaka",
      description:
        "大企業等の人材を大阪市内のベンチャー企業へ、期間限定の研修派遣機会を提供するプログラムです。開始前のヒアリングから企業間のコーディネート、派遣期間中のメンタリング、終了後のフォローアップなど、研修派遣の準備から派遣終了後まで一貫した支援を行います。本プログラムを通じ、大企業等からベンチャー企業への人材流動化の促進に取り組み、ベンチャー企業の成長加速等につなげることをめざしています。",
      url: "https://vdrive-osaka.com/",
    },
    {
      subtitle: "大阪市",
      title: "大阪市イノベーション創出支援補助金",
      city: "osaka",
      description:
        "大学の持つ研究・技術シーズをもとにした産学連携の研究開発にかかる費用の一部を補助して、その実用化に向けた取組みを推進し、もって本市の経済成長及びイノベーション創出を図ります。",
      url: "https://www.city.osaka.lg.jp/keizaisenryaku/page/0000552784.html",
    },
    {
      subtitle: "大阪市\n大阪産業局",
      title: "スタートアップビザ　大阪市",
      city: "osaka",
      description:
        "外国人起業家を支援する意欲のある地方公共団体が策定する「外国人起業活動管理支援計画」を認定し、外国人が起業しやすい新たな制度を開始します。認定された計画に基づき地方公共団体が管理・支援等を行う外国人起業家は、最長で1年間、起業準備活動のために入国・在留することが可能となります。",
      url: "https://www.city.osaka.lg.jp/keizaisenryaku/page/0000468842.html",
    },
    {
      subtitle: "大阪市",
      title: "5G等先端技術を活用したビジネス創出プロジェクト",
      city: "osaka",
      description:
        "　「5G X LAB OSAKA」の機能を活用し、5GをはじめとするAI、IoT、ロボットテクノロジー等の先端技術を活用した新たな製品・サービスの創出に挑戦する中小企業や起業志向者等に対して、官民連携により総合的なビジネスサポートをワンストップで提供します。",
      url: "https://www.city.osaka.lg.jp/keizaisenryaku/page/0000522389.html",
    },
    {
      subtitle: "大阪府\n大阪市\n大阪商工会議所",
      title: "「実証事業都市・大阪」の取組",
      city: "osaka",
      description:
        "大阪府、大阪市、大阪商工会議所は、「未来社会の実験場」をコンセプトとする2025年大阪・関西万博を見据えて、革新的な実証実験を行いやすい環境を整え、大阪で新しいビジネスを生み出す好循環を創り出し、「実証事業都市・大阪」を実現するため、「実証事業推進チーム大阪」を設置しています。\n大阪商工会議所を窓口とし、大阪府・大阪市の関連施設や公共空間、賛同いただいた民間企業の工場や施設等をフィールドとして提供する支援を行っています。",
      url: "https://www.city.osaka.lg.jp/keizaisenryaku/page/0000520364.html",
    },
    {
      subtitle: "堺市\nさかい新事業創造センター(S-Cube)",
      title: "スタートアップ実証推進事業",
      city: "sakai",
      description:
        "市内外のスタートアップ等を対象に、本市における社会課題解決やイノベーション創出に資する新たなビジネスアイデアを以下のとおり募集し、S-Cubeに設置する「さかいスタートアップ トライアル ラウンドテーブル」を通じ、支援対象事業者を選定し、中百舌鳥エリアや泉北エリアをはじめとする堺市内で行う実証機会の提供や実証事業にかかる費用の提供などによりスタートアップビジネスの実現を支援します。",
      url: "https://www.s-cube.biz/roundtable",
    },
    {
      subtitle: "京都府\n京都市\nジェトロ京都　",
      title: "スタートアップビザ京都\nSTARTUP VISA in KYOTO",
      city: "kyoto",
      description:
        "外国人起業家を支援する意欲のある地方公共団体が策定する「外国人起業活動管理支援計画」を認定し、外国人が起業しやすい新たな制度を開始します。認定された計画に基づき地方公共団体が管理・支援等を行う外国人起業家は、最長で1年間、起業準備活動のために入国・在留することが可能となります。",
      url: "https://www.kyoto-obc.jp/startupvisa/",
    },
    {
      subtitle: "公益財団法人京都産業21",
      title: "コワーキング・コワーケーション紹介サイト\nKyoto Startup Homebase",
      city: "kyoto",
      description:
        "Kyoto Startup Homebaseでは、京都府内の特徴豊かな「働く場所」の情報を紹介しています。\n京都で起業をお考えの方は、是非ここでお気に入りの拠点「働く場所」をみつけてください。\n京都があなたにとっての”Homebase”（働く場所としてふるさとや気心休まる拠点）となれば幸いです。",
      url: "https://startuphomebase.kyoto/",
    },
    {
      subtitle: "公益財団法人京都高度技術研究所",
      title: "京都市ソーシャルイノベーション研究所",
      city: "kyoto",
      description:
        "京都市ソーシャルイノベーション研究所（SILK）は、市民、企業、NPO、大学などの多種多様な組織や個人が、京都で社会的課題の解決に挑戦することで、過度の効率性や競争原理とは異なる価値観を日本はもとより、世界にも広めることを目的にした「京都市ソーシャル・イノベーション・クラスター構想」の推進拠点として2015年4月に設置されました。",
      url: "https://social-innovation.kyoto.jp/",
    },
    {
      subtitle: "一般社団法人京都知恵産業創造の森",
      title: "オープンイノベーションカフェ「KOIN」",
      city: "kyoto",
      description:
        "「KOIN（Kyoto Open platform Network）」は、京都府、京都市及び京都の産業界のオール京都体制により設立された「一般社団法人 京都知恵産業創造の森」が運営する京都経済センター3階のオープンイノベーションカフェの愛称です。「KOIN」は新しい一歩を踏み出す人のための共創の場です。 フィールドや時代を超えてここに集まった “京都の知恵と技術”が、あなたの新しい一歩を応援します。",
      url: "https://open.kyoto/",
    },
    {
      subtitle: "神戸市",
      title: "Urban Innovation KOBE",
      city: "hyogo_kobe",
      description:
        "行政が抱える課題テーマを提示し、スタートアップと協働で実証実験を行うプロジェクト。選考により選ばれたチームと行政職員が約4ヶ月間協働で新しいサービスの構築・実証を行い、実証後の本格導入を目指します。",
      url: "https://urban-innovation-japan.com/",
    },
    {
      subtitle: "神戸市",
      title: "ANCHOR KOBE",
      city: "hyogo_kobe",
      description:
        "駅直結、神戸三宮阪急ビルに開設した、スタートアップや神戸医療産業都市への進出企業、研究機関・大学や地場のものづくり企業などが交流し、イノベーションの創出や新たな連携を促進することを目的とした交流拠点。",
      url: "https://anchorkobe.com/",
    },
    {
      subtitle: "神戸市",
      title: "スタートアップビザ",
      city: "hyogo_kobe",
      description:
        "自治体が起業準備活動の管理・支援を行うことを条件に、在留資格『経営・管理』(※)の取得を目指す外国人に、起業準備として最長１年間（６ヶ月で再審査、問題なければ６ヶ月更新）の入国・在留を認める制度です。",
      url:
        "https://www.city.kobe.lg.jp/a14333/business/sangyoshinko/shokogyo/venture/newindustry/startupvisa.html",
    },
    {
      subtitle: "神戸市",
      title: "UNOPS S3i Innovation Centre Japan（神戸）",
      city: "hyogo_kobe",
      description:
        "UNOPS（国連プロジェクトサービス機関）が開設するS3i Innovation Centre Japan（神戸）は、国内外におけるスタートアップを集積・育成するとともに、日本で創出されたSDGs課題解決型サービスを世界各国の社会課題解決に繋げる支援を行うインキュベーション施設です。",
      url:
        "https://www.city.kobe.lg.jp/a14333/business/sangyoshinko/shokogyo/venture/newindustry/press/083228589324.html",
    },
    {
      subtitle: "神戸市",
      title: "エンジニア創出事業「Kobe x Engineer's Lab(神戸エンジニアラボ)」",
      city: "hyogo_kobe",
      description:
        "エンジニア人材の育成を通じて世界で活躍できる人材の輩出を目指し、実践的な“学びの機会”を提供するプログラミングサロンやスキルアップを支援する補助制度、イベントなどを実施しています。",
      url: "https://kobe-engr-lab.studio.site/",
    },
    {
      subtitle: "滋賀発成長産業発掘・育成コンソーシアム",
      title: "滋賀テックプランター",
      city: "shiga",
      description:
        "滋賀県に集積する理工系大学の研究シーズやモノづくり企業の技術シーズ、琵琶湖に関連する水・環境技術における研究成果といった地域の強みを活かし、滋賀から世界を変えようとする研究者・技術者・起業家を育成する創業支援プログラム。\n事業化までに必要な道筋をともに考え、各種支援を行います。また、エントリーチームが企業や研究者等に連携を訴求するイベントとして「滋賀テックプラングランプリ」を開催しています。",
      url: "https://shiga-consortium.com",
    },
    {
      subtitle: "兵庫県",
      title: "起業プラザひょうご（神戸・姫路・尼崎）",
      city: "hyogo_kobe",
      description:
        "兵庫県内において起業・創業の機運を高めるため、スモールオフィス等の起業の場や交流機能を備えた起業・創業の拠点施設「起業プラザひょうご」を神戸・姫路・尼崎の県内3か所に開設しています。",
      url: "https://web.pref.hyogo.lg.jp/sr10/kigyoplaza.html",
    },
    {
      subtitle: "京都府\n一般社団法人京都発明協会",
      title: "京都府スタートアップグローバル知財サポートデスク",
      city: "kyoto",
      description:
        "グローバル展開を目指すスタートアップ企業等に対し、各国の様々な知的財産に関する法規の知識戦略のノウハウを待つ専門家による知財相談や戦略立案、海外出願・侵害対策等の支援を実施する窓口（京都府スタートアップグローバル知財サポートデスク）を常設することにより、スタートアップ企業の成長発展を支援する。",
      url: "https://www.kyoto-hatsumei.com/support_desk/index",
    },
    {
      subtitle: "京都府",
      title: "京都府アクセラレーションプログラム",
      city: "kyoto",
      description:
        "大企業を対象にして社内の意識改革やイントレプレナー人材を育成しながら、参加大企業が持つ課題や事業アイデアを基に新規事業立案から事業化までを集中的に支援するインキュベーションプログラムを実施し、将来的に京都から大企業発カーブアウトスタートアップ企業の創出を図る。",
      url: "",
    },
  ] as StatusType[],
};

export { STARTUP_STATUS_EN, STARTUP_STATUS_JP };
