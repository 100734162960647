import {
  Accelerate,
  Award,
  Consulting,
  Equipment,
  Fund,
  Incubation,
  Loan,
  Matching,
  Other,
  Pitch,
  Portal,
  Seminar,
  Subsidiary,
  Support,
} from "../assets/support-icons";

const STARTUP_SUPPORT_MENUS_EN = [
  {
    label: "Hardware Acceleration",
    key: "menu0",
    icon: <Accelerate />,
  },
  {
    label: "Seminars / Workshops / Study Sessions",
    key: "menu1",
    icon: <Seminar />,
  },
  {
    label: "Consultation / Expert Dispatch / Mentoring",
    key: "menu2",
    icon: <Consulting />,
  },
  {
    label: "Awards",
    key: "menu3",
    icon: <Award />,
  },
  {
    label: "Pitch Events",
    key: "menu4",
    icon: <Pitch />,
  },
  {
    label: "Business Incubation",
    key: "menu5",
    icon: <Incubation />,
  },
  {
    label: "Subsidies / Grants / Incentives",
    key: "menu6",
    icon: <Subsidiary />,
  },
  {
    label: "Comprehensive Support / Hands-on Support",
    key: "menu7",
    icon: <Support />,
  },
  {
    label: "Portal Site / Information Dissemination",
    key: "menu8",
    icon: <Portal />,
  },
  // {
  //   label: "Equipment",
  //   key: "menu9",
  //   icon: <Equipment />,
  // },
  {
    label: "Funds",
    key: "menu9",
    icon: <Fund />,
  },
  {
    label: "Loans / Equity Loans",
    key: "menu10",
    icon: <Loan />,
  },
  {
    label: "Matching",
    key: "menu11",
    icon: <Matching />,
  },
  {
    label: "Others",
    key: "menu12",
    icon: <Other />,
  },
];

const STARTUP_SUPPORT_MENUS_JA = [
  {
    label: "アクセラレーション",
    key: "menu0",
    icon: <Accelerate />,
  },
  {
    label: "セミナー／ワークショップ／勉強会",
    key: "menu1",
    icon: <Seminar />,
  },
  {
    label: "相談／専門家派遣／メンタリング",
    key: "menu2",
    icon: <Consulting />,
  },
  {
    label: "アワード",
    key: "menu3",
    icon: <Award />,
  },
  {
    label: "ピッチイベント",
    key: "menu4",
    icon: <Pitch />,
  },
  {
    label: "インキュベーション",
    key: "menu5",
    icon: <Incubation />,
  },
  {
    label: "補助金／助成金／奨励金",
    key: "menu6",
    icon: <Subsidiary />,
  },
  {
    label: "総合支援／ハンズオン支援",
    key: "menu7",
    icon: <Support />,
  },
  {
    label: "ポータルサイト／情報提供",
    key: "menu8",
    icon: <Portal />,
  },
  {
    label: "機器利用",
    key: "menu9",
    icon: <Equipment />,
  },
  {
    label: "ファンド",
    key: "menu10",
    icon: <Fund />,
  },
  {
    label: "融資／資本性ローン",
    key: "menu11",
    icon: <Loan />,
  },
  {
    label: "マッチング",
    key: "menu12",
    icon: <Matching />,
  },
  {
    label: "その他",
    key: "menu13",
    icon: <Other />,
  },
];

export { STARTUP_SUPPORT_MENUS_JA, STARTUP_SUPPORT_MENUS_EN };
